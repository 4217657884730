import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { AccountAction, RootReducerState } from 'src/redux';
import { coreUtils } from 'src/common';
import { DefaultLimit } from 'src/globals/constants';
import { IGetUserActivitiesVariable, IUserActivity } from 'src/gql/user-activity/types';
import { Flex, Spinner, Table, Tbody, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared';
import { Pagination, EmptyView } from 'src/components';
import { styles } from '../styles';
import UserActivityRow from './UserActivityRow';

interface UserActivityListProps {
  userActivitiesTotal: number;
  userActivities: IUserActivity[];
  getUserActivities: (
    variables: IGetUserActivitiesVariable,
    callback?: (success: boolean) => any
  ) => void;
  metaMaskWalletAddress?: string;
}

const UserActivityList = (props: UserActivityListProps) => {
  const { t } = useTranslation();

  const { userActivitiesTotal, userActivities, getUserActivities, metaMaskWalletAddress } = props;
  const { search } = useLocation();

  const [loadingActivities, setLoadingActivities] = useState(false);

  useEffect(() => {
    if (!metaMaskWalletAddress) return;

    const queryParams = queryString.parse(search);
    const variables = coreUtils.standardizeVariablesForQuery(queryParams);

    setLoadingActivities(true);
    getUserActivities(
      {
        ...variables,
        user: metaMaskWalletAddress,
      },
      () => setLoadingActivities(false)
    );
  }, [search, getUserActivities, metaMaskWalletAddress]);

  const primaryColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const emptyColor = useColorModeValue(Colors.light.bg, Colors.dark.bg);
  const pageTotal = Math.ceil(userActivitiesTotal / DefaultLimit);
  return (
    <Flex flex={{ base: 1 }} flexDirection="column" pos="relative" overflowX={'auto'}>
      {Boolean(userActivities.length) && (
        <Table width={'full'}>
          <Thead>
            <Tr>
              <Th {...styles.column}>{t('Component:UserActivity.Header.Transaction')}</Th>
              <Th {...styles.column}>{t('Component:UserActivity.Header.Item')}</Th>
              <Th {...styles.column}>{t('Component:UserActivity.Header.Type')}</Th>
              <Th {...styles.column}>{t('Component:UserActivity.Header.Action')}</Th>
              <Th {...styles.column}>{t('Component:UserActivity.Header.Time')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userActivities.map((activity, index: number) => (
              <UserActivityRow key={index} activity={activity} />
            ))}
          </Tbody>
        </Table>
      )}

      {!loadingActivities && !userActivities.length && (
        <EmptyView description={t('Component:UserActivityList.NoActivity')} mt="4" />
      )}

      {/* {Boolean(userActivities.length) && <Pagination pageTotal={pageTotal} />} */}
      <Pagination pageTotal={pageTotal} />

      {loadingActivities && (
        <Spinner
          className="spinner"
          thickness="5px"
          speed="0.65s"
          emptyColor={emptyColor}
          color={primaryColor}
          size="xl"
          pos="absolute"
        />
      )}
    </Flex>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  const { account, wallet } = state;
  const { userActivities, userActivitiesTotal } = account;

  return {
    userActivities,
    userActivitiesTotal,
    metaMaskWalletAddress: wallet.metaMaskWalletAddress,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getUserActivities: (
      variables: IGetUserActivitiesVariable,
      callback?: (success: boolean) => any
    ) => dispatch<any>(AccountAction.getUserActivities(variables, callback)), // TODO: Refactor redundant function signature
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActivityList);
