import get from 'lodash/get';
import { IAction } from '../types';
import * as Types from './types';

const initialState: Types.InventoryState = {
  isGettingMyMiningTools: false,
  gettingMyMiningToolsSuccess: false,
  gettingMyMiningToolsMessage: '',
  listOfMyMiningTools: [],
  totalOfMyMiningTools: 0,
  isGettingCurrentMiningTools: false,
  gettingCurrentMiningToolsSuccess: false,
  gettingCurrentMiningToolsMessage: '',
  listOfCurrentMiningTools: null,
  currentMyNFT: null,
  isGettingCurrentMyNFT: false,
  gettingCurrentMyNFTSuccess: false,
  gettingCurrentMyNFTMessage: '',
  isGettingMyLands: false,
  gettingMyLandsSuccess: false,
  gettingMyLandsMessage: '',
  listOfMyLands: [],
  totalOfMyLands: 0,
  isGettingMyGenes: false,
  gettingMyGenesSuccess: false,
  gettingMyGenesMessage: '',
  listOfMyGenes: [],
  totalOfMyGenes: 0,
  isGettingMyGachaBoxes: false,
  gettingMyGachaBoxesSuccess: false,
  gettingMyGachaBoxesMessage: '',
  listOfMyGachaBoxes: [],
  totalOfMyGachaBoxes: 0,
  isGettingMyToolMaterials: false,
  gettingMyToolMaterialsSuccess: false,
  gettingMyToolMaterialsMessage: '',
  listOfMyToolMaterials: [],
  totalOfMyToolMaterials: 0,
};

const inventoryReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case Types.GET_MY_MINING_TOOLS_REQUEST:
      return {
        ...state,
        isGettingMyMiningTools: true,
        gettingMyMiningToolsSuccess: false,
        gettingMyMiningToolsMessage: '',
      };
    case Types.GET_MY_MINING_TOOLS_SUCCESS: {
      const data = get(action, 'payload.records', []);
      const total = get(action, 'payload.total', 0);
      return {
        ...state,
        isGettingMyMiningTools: false,
        gettingMyMiningToolsSuccess: true,
        gettingMyMiningToolsMessage: '',
        listOfMyMiningTools: data,
        totalOfMyMiningTools: total,
      };
    }
    case Types.GET_MY_MINING_TOOLS_FAILURE:
      return {
        ...state,
        isGettingMyMiningTools: false,
        gettingMyMiningToolsSuccess: false,
        gettingMyMiningToolsMessage: action.payload.errorMessage,
        listOfMyMiningTools: [],
        totalOfMyMiningTools: 0,
      };
    case Types.GET_CURRENT_MINING_TOOLS_REQUEST:
      return {
        ...state,
        isGettingCurrentMiningTools: true,
        gettingCurrentMiningToolsSuccess: false,
        gettingCurrentMiningToolsMessage: '',
      };
    case Types.GET_CURRENT_MINING_TOOLS_SUCCESS: {
      const data = get(action, 'payload', []);

      return {
        ...state,
        isGettingCurrentMiningTools: false,
        gettingCurrentMiningToolsSuccess: true,
        gettingCurrentMiningToolsMessage: '',
        listOfCurrentMiningTools: data,
      };
    }
    case Types.GET_CURRENT_MINING_TOOLS_FAILURE:
      return {
        ...state,
        isGettingCurrentMiningTools: false,
        gettingCurrentMiningToolsSuccess: false,
        gettingCurrentMiningToolsMessage: action.payload.errorMessage,
        listOfCurrentMiningTools: null,
      };
    case Types.GET_MY_LANDS_REQUEST:
      return {
        ...state,
        isGettingMyLands: true,
        gettingMyLandsSuccess: false,
        gettingMyLandsMessage: '',
      };
    case Types.GET_MY_LANDS_SUCCESS: {
      const data = get(action, 'payload.records', []);
      const total = get(action, 'payload.total', 0);
      return {
        ...state,
        isGettingMyLands: false,
        gettingMyLandsSuccess: true,
        gettingMyLandsMessage: '',
        listOfMyLands: data,
        totalOfMyLands: total,
      };
    }
    case Types.GET_MY_LANDS_FAILURE:
      return {
        ...state,
        isGettingMyLands: false,
        gettingMyLandsSuccess: false,
        gettingMyLandsMessage: action.payload.errorMessage,
        listOfMyLands: [],
        totalOfMyLands: 0,
      };
    case Types.GET_MY_GENES_REQUEST:
      return {
        ...state,
        isGettingMyGenes: true,
        gettingMyGenesSuccess: false,
        gettingMyGenesMessage: '',
      };
    case Types.GET_MY_GENES_SUCCESS: {
      const data = get(action, 'payload.records', []);
      const total = get(action, 'payload.total', 0);
      return {
        ...state,
        isGettingMyGenes: false,
        gettingMyGenesSuccess: true,
        gettingMyGenesMessage: '',
        listOfMyGenes: data,
        totalOfMyGenes: total,
      };
    }
    case Types.GET_MY_GENES_FAILURE:
      return {
        ...state,
        isGettingMyGenes: false,
        gettingMyGenesSuccess: false,
        gettingMyGenesMessage: action.payload.errorMessage,
        listOfMyGenes: [],
        totalOfMyGenes: 0,
      };
    case Types.GET_MY_GACHA_BOXES_REQUEST:
      return {
        ...state,
        isGettingMyGachaBoxes: true,
        gettingMyGachaBoxesSuccess: false,
        gettingMyGachaBoxesMessage: '',
      };
    case Types.GET_MY_GACHA_BOXES_SUCCESS: {
      const data = get(action, 'payload.records', []);
      const total = get(action, 'payload.total', 0);
      return {
        ...state,
        isGettingMyGachaBoxes: false,
        gettingMyGachaBoxesSuccess: true,
        gettingMyGachaBoxesMessage: '',
        listOfMyGachaBoxes: data,
        totalOfMyGachaBoxes: total,
      };
    }
    case Types.GET_MY_GACHA_BOXES_FAILURE:
      return {
        ...state,
        isGettingMyGachaBoxes: false,
        gettingMyGachaBoxesSuccess: false,
        gettingMyGachaBoxesMessage: action.payload.errorMessage,
        listOfMyGachaBoxes: [],
        totalOfMyGachaBoxes: 0,
      };
    case Types.GET_MY_TOOL_MATERIALS_REQUEST:
      return {
        ...state,
        isGettingMyToolMaterials: true,
        gettingMyToolMaterialsSuccess: false,
        gettingMyToolMaterialsMessage: '',
      };
    case Types.GET_MY_TOOL_MATERIALS_SUCCESS: {
      const data = get(action, 'payload.records', []);
      const total = get(action, 'payload.total', 0);
      return {
        ...state,
        isGettingMyToolMaterials: false,
        gettingMyToolMaterialsSuccess: true,
        gettingMyToolMaterialsMessage: '',
        listOfMyToolMaterials: data,
        totalOfMyToolMaterials: total,
      };
    }
    case Types.GET_MY_TOOL_MATERIALS_FAILURE:
      return {
        ...state,
        isGettingMyToolMaterials: false,
        gettingMyToolMaterialsSuccess: false,
        gettingMyToolMaterialsMessage: action.payload.errorMessage,
        listOfMyToolMaterials: [],
        totalOfMyToolMaterials: 0,
      };
    case Types.GET_CURRENT_MY_NFT_REQUEST: {
      return {
        ...state,
        currentMyNFT: null,
        isGettingCurrentMyNFT: true,
        gettingCurrentMyNFTSuccess: false,
        gettingCurrentMyNFTMessage: '',
      };
    }
    case Types.GET_CURRENT_MY_NFT_SUCCESS:
      return {
        ...state,
        currentMyNFT: { ...state.currentMyNFT, ...action.payload },
        isGettingCurrentMyNFT: false,
        gettingCurrentMyNFTSuccess: true,
        gettingCurrentMyNFTMessage: '',
      };
    case Types.GET_CURRENT_MY_NFT_FAILURE:
      return {
        ...state,
        isGettingCurrentMyNFT: false,
        gettingCurrentMyNFTSuccess: false,
        gettingCurrentMyNFTMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default inventoryReducer;
