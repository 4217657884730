import React, { memo } from 'react';
import { Box, Heading, Image, Stack, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { Colors } from 'src/shared';
import { appUtils, coreUtils } from 'src/common';
import BN from 'bn.js';
import { IFixedPriceSaleNFT } from 'src/gql/types';
import { FixedPriceSaleStatus, NFTType } from 'src/globals/constants';
import { FixedPriceSaleLabel, QuantityLabel } from 'src/components';
import { getTemplateId } from '../../../common/coreUtils';

export interface NFTItemProps {
  item: IFixedPriceSaleNFT;
  rootPath: string;
}

const FixedPriceSaleNFTItem = (props: NFTItemProps) => {
  const { item, rootPath } = props;
  const borderColor = useColorModeValue(Colors.light.divider, Colors.dark.divider);
  const bg = useColorModeValue(Colors.light.cardBg, Colors.dark.cardBg);
  const hoverColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const name = get(item, 'item.metadata.name', '') || '';
  const tokenIndex = get(item, 'tokenIdDetail.index', '');
  const priceInfo = coreUtils.getPriceInfoByStatus(item);
  const nftType = get(item, 'item.type', '');
  const title = `${name} #${tokenIndex}`;

  const isAvailableOnSale = item?.status === FixedPriceSaleStatus.AVAILABLE;
  const nftItemImage =
    nftType === NFTType.LAND
      ? coreUtils.getImageByTokenId(new BN(item.tokenId), nftType)
      : coreUtils.getImageByTemplateId(getTemplateId(new BN(item.tokenId)), nftType);
  const linkTo = appUtils.getNFTDetailUrl(item.id, rootPath);
  return (
    <Box
      maxW={nftType === NFTType.LAND ? '350px' : '260px'}
      w={'full'}
      p={[2, 4]}
      overflow={'hidden'}
    >
      <Link to={{ pathname: linkTo, state: { currentFixedPriceSaleNFT: item } }}>
        <Box
          w={'full'}
          bg={bg}
          p={[2, 4]}
          rounded={'borderItem'}
          overflow={'hidden'}
          border={1}
          borderStyle={'solid'}
          borderColor={borderColor}
          _hover={{
            borderColor: hoverColor,
          }}
        >
          <Box mb={[2, 4]}>
            {nftType === NFTType.LAND ? (
              <Image {...styles.landImage} src={nftItemImage} />
            ) : (
              <Image {...styles.image} src={nftItemImage} />
            )}
          </Box>

          <Box ph={2}>
            <Stack spacing={0} align={'left'} mb={2}>
              <Heading fontSize={'l'} fontWeight={700} fontFamily={'body'} isTruncated>
                {title}
              </Heading>
            </Stack>

            <Stack direction={'row'} justify={'space-between'} alignItems={'center'} spacing={6}>
              <QuantityLabel quantity={item?.amount} />
              {isAvailableOnSale && (
                <FixedPriceSaleLabel
                  data={{
                    price: priceInfo?.origin,
                    priceCurrency: priceInfo?.originalCurrency,
                  }}
                />
              )}
            </Stack>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

const styles = {
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as 'contain',
  },
  landImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as 'contain',
    borderRadius: '4px',
    border: '1px solid white',
  },
};

export default memo(FixedPriceSaleNFTItem);
