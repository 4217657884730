import React from 'react';
import { NFTType, RoutePath } from 'src/globals/constants';
import LoginPage from 'src/pages/Login/Login';
import ProfilePage from 'src/pages/Profile/Profile';
import {
  MyLandsPage,
  MyMiningToolsPage,
  MyGenesPage,
  MyNFTDetailPage,
  MyGachaBoxesPage,
  MyToolMaterialsPage,
} from 'src/pages/Inventory';
import { MiningToolDetailPage, MiningToolPage } from 'src/pages/MiningTool';
import { LandDetailPage, LandPage } from 'src/pages/Land';
import { GeneDetailPage, GenePage } from 'src/pages/Gene';
import UserActivity from 'src/pages/UserActivity';
import { ToolMaterialDetailPage, ToolMaterialPage } from 'src/pages/ToolMaterial';
import { GachaBoxDetailPage, GachaBoxPage } from 'src/pages/GachaBox';
import { HomePage } from 'src/pages/Home';
import { DashboardPage } from 'src/pages/Dashboard';

export const PublicRoutes = [
  {
    path: RoutePath.ROOT,
    component: HomePage,
    exact: true,
  },
  {
    path: RoutePath.DASHBOARD,
    component: DashboardPage,
    exact: true,
  },
  {
    path: RoutePath.GENE,
    component: GenePage,
    exact: true,
  },
  {
    path: `${RoutePath.GENE}/:saleId`,
    component: GeneDetailPage,
    exact: false,
  },
  {
    path: RoutePath.MINING_TOOL,
    component: MiningToolPage,
    exact: true,
  },
  {
    path: `${RoutePath.MINING_TOOL}/:saleId`,
    component: MiningToolDetailPage,
    exact: false,
  },
  {
    path: RoutePath.LAND,
    component: LandPage,
    exact: true,
  },
  {
    path: `${RoutePath.LAND}/:saleId`,
    component: LandDetailPage,
    exact: false,
  },
  {
    path: RoutePath.GACHA_BOX,
    component: GachaBoxPage,
    exact: true,
  },
  {
    path: `${RoutePath.GACHA_BOX}/:saleId`,
    component: GachaBoxDetailPage,
    exact: false,
  },
  {
    path: RoutePath.TOOL_MATERIAL,
    component: ToolMaterialPage,
    exact: true,
  },
  {
    path: `${RoutePath.TOOL_MATERIAL}/:saleId`,
    component: ToolMaterialDetailPage,
    exact: false,
  },
];

// @ts-ignore
export const PrivateRoutes = [
  {
    path: RoutePath.PROFILE,
    component: ProfilePage,
    exact: true,
  },
  {
    path: RoutePath.INVENTORY_MINING_TOOL,
    component: MyMiningToolsPage,
    exact: true,
  },
  {
    path: `${RoutePath.INVENTORY_MINING_TOOL}/:tokenId`,
    component: () => <MyNFTDetailPage nftType={NFTType.MINING_TOOL} />,
    exact: false,
  },
  {
    path: RoutePath.INVENTORY_LAND,
    component: MyLandsPage,
    exact: true,
  },
  {
    path: `${RoutePath.INVENTORY_LAND}/:tokenId`,
    component: () => <MyNFTDetailPage nftType={NFTType.LAND} />,
    exact: false,
  },
  {
    path: RoutePath.INVENTORY_GENE,
    component: MyGenesPage,
    exact: true,
  },
  {
    path: `${RoutePath.INVENTORY_GENE}/:tokenId`,
    component: () => <MyNFTDetailPage nftType={NFTType.GENE} />,
    exact: false,
  },
  {
    path: RoutePath.INVENTORY_GACHA_BOX,
    component: MyGachaBoxesPage,
    exact: true,
  },
  {
    path: `${RoutePath.INVENTORY_GACHA_BOX}/:tokenId`,
    component: () => <MyNFTDetailPage nftType={NFTType.GACHA} />,
    exact: false,
  },
  {
    path: RoutePath.INVENTORY_TOOL_MATERIAL,
    component: MyToolMaterialsPage,
    exact: true,
  },
  {
    path: `${RoutePath.INVENTORY_TOOL_MATERIAL}/:tokenId`,
    component: () => <MyNFTDetailPage nftType={NFTType.TOOL_MATERIAL} />,
    exact: false,
  },
  {
    path: RoutePath.INVENTORY,
    component: MyMiningToolsPage,
    exact: true,
  },
  {
    path: RoutePath.USER_ACTIVITY,
    component: UserActivity,
    exact: true,
  },
];

export const OnlyPublicRoutes = [
  {
    path: RoutePath.LOGIN,
    component: LoginPage,
    exact: true,
  },
];
