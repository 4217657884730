import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppAction, RootReducerState } from 'src/redux';
import { InventoryLayout } from 'src/layouts';
import { DefaultOwnerAddress, NFTType } from 'src/globals/constants';
import { Filter } from 'src/features/filter/components';
import { MyNFTList } from 'src/features/nftList';
import InventoryHeader from './InventoryHeader';

const MyMiningTools = () => {
  const dispatch = useDispatch();

  const metaMaskWalletAddress = useSelector(
    (state: RootReducerState) => state.wallet.metaMaskWalletAddress
  );

  const filterConfigs = useSelector((state: RootReducerState) => state.app.filterConfigs);

  const renderFilterContent = () => (
    <Filter
      filterBase={filterConfigs.miningTool}
      onBack={() => dispatch(AppAction.toggleInventoryFilter(false))}
    />
  );

  const nftType = NFTType.MINING_TOOL;

  return (
    <InventoryLayout renderFilterContent={renderFilterContent}>
      <Flex
        flex={{ base: 1 }}
        p={{ base: 2, md: 4 }}
        flexDirection={'column'}
        overflowY={'auto'}
        height={'inherit'}
      >
        <InventoryHeader nftType={nftType} />
        <MyNFTList ownerId={metaMaskWalletAddress || DefaultOwnerAddress} nftType={nftType} />
      </Flex>
    </InventoryLayout>
  );
};

export default MyMiningTools;
