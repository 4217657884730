import React from 'react';
import { IGeneMetadata, IMiningToolMetadata, TNFTMetadata, TNFTType } from 'src/gql/types';
import { NFTType } from 'src/globals/constants';
import { Text, TextProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import MiningToolAbouts from './MiningToolAbouts';
import GeneAbouts from './GeneAbouts';
import StandardAbouts from './StandardAbouts';

interface NFTAttributesProps {
  metadata: TNFTMetadata;
  nftType: TNFTType;
  showTitle?: boolean;
  titleStyle?: TextProps;
}

const NFTAbouts = (props: NFTAttributesProps) => {
  const { metadata, nftType, showTitle = true, titleStyle } = props;
  const { t } = useTranslation();
  const renderAboutContent = () => {
    switch (nftType) {
      case NFTType.GENE:
        return <GeneAbouts metadata={metadata as IGeneMetadata} />;
      case NFTType.MINING_TOOL:
        return <MiningToolAbouts metadata={metadata as IMiningToolMetadata} />;
      default:
    }
    return <StandardAbouts nftType={nftType} />;
  };

  const titleSectionStyle = { ...styles.titleSection, ...titleStyle };
  return (
    <>
      {showTitle && <Text {...titleSectionStyle}>{t('Component:NFTDetail.Section.About')}</Text>}
      {renderAboutContent()}
    </>
  );
};

export default React.memo(NFTAbouts);

const styles = {
  titleSection: {
    fontSize: 'lg',
    pt: 4,
    pb: 2,
    fontWeight: 'bold' as 'bold',
  },
};
