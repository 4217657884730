import { INFT } from 'src/gql/types';

export const GET_MY_MINING_TOOLS_REQUEST = 'GET_MY_MINING_TOOLS_REQUEST';
export const GET_MY_MINING_TOOLS_SUCCESS = 'GET_MY_MINING_TOOLS_SUCCESS';
export const GET_MY_MINING_TOOLS_FAILURE = 'GET_MY_MINING_TOOLS_FAILURE';

export const GET_CURRENT_MINING_TOOLS_REQUEST = 'GET_CURRENT_MINING_TOOLS_REQUEST';
export const GET_CURRENT_MINING_TOOLS_SUCCESS = 'GET_CURRENT_MINING_TOOLS_SUCCESS';
export const GET_CURRENT_MINING_TOOLS_FAILURE = 'GET_CURRENT_MINING_TOOLS_FAILURE';

export const GET_MY_LANDS_REQUEST = 'GET_MY_LANDS_REQUEST';
export const GET_MY_LANDS_SUCCESS = 'GET_MY_LANDS_SUCCESS';
export const GET_MY_LANDS_FAILURE = 'GET_MY_LANDS_FAILURE';

export const GET_MY_GENES_REQUEST = 'GET_MY_GENES_REQUEST';
export const GET_MY_GENES_SUCCESS = 'GET_MY_GENES_SUCCESS';
export const GET_MY_GENES_FAILURE = 'GET_MY_GENES_FAILURE';

export const GET_MY_GACHA_BOXES_REQUEST = 'GET_MY_GACHA_BOXES_REQUEST';
export const GET_MY_GACHA_BOXES_SUCCESS = 'GET_MY_GACHA_BOXES_SUCCESS';
export const GET_MY_GACHA_BOXES_FAILURE = 'GET_MY_GACHA_BOXES_FAILURE';

export const GET_MY_TOOL_MATERIALS_REQUEST = 'GET_MY_TOOL_MATERIALS_REQUEST';
export const GET_MY_TOOL_MATERIALS_SUCCESS = 'GET_MY_TOOL_MATERIALS_SUCCESS';
export const GET_MY_TOOL_MATERIALS_FAILURE = 'GET_MY_TOOL_MATERIALS_FAILURE';

export const GET_CURRENT_MY_NFT_REQUEST = 'GET_CURRENT_MY_NFT_REQUEST';
export const GET_CURRENT_MY_NFT_SUCCESS = 'GET_CURRENT_MY_NFT_SUCCESS';
export const GET_CURRENT_MY_NFT_FAILURE = 'GET_CURRENT_MY_NFT_FAILURE';

export interface InventoryState {
  isGettingCurrentMyNFT: boolean;
  gettingCurrentMyNFTSuccess: boolean;
  gettingCurrentMyNFTMessage: string;
  currentMyNFT: INFT | null;
  isGettingMyMiningTools: boolean;
  gettingMyMiningToolsSuccess: boolean;
  gettingMyMiningToolsMessage: string;
  listOfMyMiningTools: Array<INFT>;
  totalOfMyMiningTools: number;
  isGettingCurrentMiningTools: boolean;
  gettingCurrentMiningToolsSuccess: boolean;
  gettingCurrentMiningToolsMessage: string;
  listOfCurrentMiningTools: Array<string> | null;
  isGettingMyLands: boolean;
  gettingMyLandsSuccess: boolean;
  gettingMyLandsMessage: string;
  listOfMyLands: Array<INFT>;
  totalOfMyLands: number;
  isGettingMyGenes: boolean;
  gettingMyGenesSuccess: boolean;
  gettingMyGenesMessage: string;
  listOfMyGenes: Array<INFT>;
  totalOfMyGenes: number;
  isGettingMyGachaBoxes: boolean;
  gettingMyGachaBoxesSuccess: boolean;
  gettingMyGachaBoxesMessage: string;
  listOfMyGachaBoxes: Array<INFT>;
  totalOfMyGachaBoxes: number;
  isGettingMyToolMaterials: boolean;
  gettingMyToolMaterialsSuccess: boolean;
  gettingMyToolMaterialsMessage: string;
  listOfMyToolMaterials: Array<INFT>;
  totalOfMyToolMaterials: number;
}
