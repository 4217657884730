import React, { memo, useEffect } from 'react';
import { Box, Heading, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { Colors } from 'src/shared';
import { appUtils, coreUtils } from 'src/common';
import BN from 'bn.js';
import { INFT, TNFTType } from 'src/gql/types';
import { SaleQuantityLabel } from 'src/components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getTemplateId } from '../../../common/coreUtils';
import { NFTType } from '../../../globals/constants';
import { RootReducerState } from '../../../redux';

export interface NFTItemProps {
  item: INFT;
  nftType: TNFTType;
  rootPath: string;
}

const NFTItem = (props: NFTItemProps) => {
  const { item, nftType, rootPath } = props;
  const { t } = useTranslation();
  const [isMining, setIsMining] = React.useState<boolean>(false);
  const borderColor = useColorModeValue(Colors.light.divider, Colors.dark.divider);
  const bg = useColorModeValue(Colors.light.cardBg, Colors.dark.cardBg);
  const hoverColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const backgroundColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const name = get(item, 'metadata.name', '') || '';
  const tokenIndex = get(item, 'tokenIdDetail.index', '');
  const title = `${name} #${tokenIndex}`;
  const miningToolsId = useSelector(
    (state: RootReducerState) => state.inventory.listOfCurrentMiningTools
  );

  const nftItemImage =
    nftType === NFTType.LAND
      ? coreUtils.getImageByTokenId(new BN(item.tokenId), nftType)
      : coreUtils.getImageByTemplateId(getTemplateId(new BN(item.tokenId)), nftType);
  const linkTo = appUtils.getNFTDetailUrl(item.tokenId, rootPath);

  const isMiningStyle = {
    fontSize: 'xs',
    fontWeight: 700,
    fontFamily: 'body',
    backgroundColor: backgroundColor,
    borderRadius: 'full',
    padding: '4px 7px',
  };

  useEffect(() => {
    if (nftType === NFTType.MINING_TOOL) {
      setIsMining(miningToolsId?.includes(item.tokenId) || false);
    }
  }, [nftType, item.tokenId, miningToolsId]);

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as 'contain',
    borderRadius: nftType === NFTType.LAND ? '4px' : '0px',
    border: nftType === NFTType.LAND ? '1px solid white' : 'none',
    filter: isMining ? 'grayscale(100%)' : 'none',
  };

  return (
    <Box
      maxW={nftType === NFTType.LAND ? '350px' : '260px'}
      w={'full'}
      p={[2, 4]}
      overflow={'hidden'}
    >
      <Link to={{ pathname: linkTo, state: { currentNFT: item } }}>
        <Box
          w={'full'}
          bg={bg}
          p={[2, 4]}
          rounded={'borderItem'}
          overflow={'hidden'}
          border={1}
          borderStyle={'solid'}
          borderColor={borderColor}
          _hover={{
            borderColor: hoverColor,
          }}
        >
          <Box mb={[2, 4]}>
            <Image {...imageStyle} src={nftItemImage} />
          </Box>

          <Box ph={2}>
            <Stack spacing={0} align={'left'} mb={2}>
              <Heading fontSize={'l'} fontWeight={700} fontFamily={'body'} isTruncated>
                {title}
              </Heading>
            </Stack>

            <Stack direction="row" justify="flex-end" alignItems="center" spacing={6}>
              {isMining && (
                <Box>
                  <Text {...isMiningStyle}>{`${t('Common:IsMining')}`}</Text>
                </Box>
              )}
              <SaleQuantityLabel
                saleQuantity={item?.onSaleAmount}
                totalQuantity={item?.totalAmount}
              />
            </Stack>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export default memo(NFTItem);
