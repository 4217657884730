import BN from 'bn.js';
import { getOriginalTemplateId } from '../common/coreUtils';
import request from '../clients/assetClient';
import { IGeneMetadata, ILandMetadata, IMiningToolMetadata } from '../gql/types';
import { coreUtils } from '../common';

export const getToolMetadata = async (templateId: string): Promise<IMiningToolMetadata> => {
  const originalTemplateId = getOriginalTemplateId(new BN(templateId));
  const res = await request.get(`/data/tools/${coreUtils.padTemplateId(originalTemplateId)}.json`);
  const { id, name, cat, rarity, supply, weight, cd, mp, luck } = res.data;
  return {
    templateId: id,
    name,
    cat,
    rarity,
    supply,
    weight,
    cd,
    mp,
    luck,
  } as IMiningToolMetadata;
};

export const getGeneMetadata = async (templateId: string): Promise<IGeneMetadata> => {
  const originalTemplateId = getOriginalTemplateId(new BN(templateId));
  const res = await request.get(`/data/genes/${coreUtils.padTemplateId(originalTemplateId)}.json`);

  const data = res.data.template;

  return {
    templateId: data.id,
    name: data.name,
    supply: data.supply,
    weight: data.weight,
    geneClass: data.class,
    part: data.part,
    rarity: data.rarity,
  } as IGeneMetadata;
};

export const getLandMetadata = async (templateId: string): Promise<ILandMetadata> => {
  const originalTemplateId = getOriginalTemplateId(new BN(templateId));
  const res = await request.get(`/data/lands/${coreUtils.padTemplateId(originalTemplateId)}.json`);

  const { id, name, supply, weight, cd, mp, luck } = res.data.template;
  return {
    templateId: id,
    name,
    supply,
    weight,
    cd,
    mp,
    luck,
  } as ILandMetadata;
};
