import { gql } from '@apollo/client';

export const TOKEN_ID_DETAIL_KEY = 'tokenIdDetail';
export const getTokenIdDetailFragment = (key = TOKEN_ID_DETAIL_KEY) => gql`
  fragment ${key} on TokenIdDetail {
     index
     templateId
     contractId
  }
`;
