import { NFTType, RoutePath } from 'src/globals/constants';
import { TNFTType } from 'src/gql/types';

export const getEmptyMessageByNFTType = (nftType: TNFTType): string => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return 'Component:NFTList.Message.MiningToolEmpty';
    case NFTType.GENE:
      return 'Component:NFTList.Message.GeneEmpty';
    case NFTType.LAND:
      return 'Component:NFTList.Message.LandEmpty';
    case NFTType.GACHA:
      return 'Component:NFTList.Message.GachaBoxEmpty';
    case NFTType.TOOL_MATERIAL:
      return 'Component:NFTList.Message.ToolMaterialEmpty';
    default:
      throw new Error(`Please implement getEmptyMessageByNFTType with type ${nftType}`);
  }
};

export const getInventoryPathByNFTType = (nftType: TNFTType): string => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return RoutePath.INVENTORY_MINING_TOOL;
    case NFTType.GENE:
      return RoutePath.INVENTORY_GENE;
    case NFTType.LAND:
      return RoutePath.INVENTORY_LAND;
    case NFTType.GACHA:
      return RoutePath.INVENTORY_GACHA_BOX;
    case NFTType.TOOL_MATERIAL:
      return RoutePath.INVENTORY_TOOL_MATERIAL;
    default:
      return RoutePath.ROOT;
  }
};
