import { Image, HStack, DeepPartial, Text } from '@chakra-ui/react';
import BN from 'bn.js';
import { INFT, TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import { NFTType } from 'src/globals/constants';
import React from 'react';
import { getTemplateId } from '../../../common/coreUtils';

interface Props {
  item: DeepPartial<INFT>;
}

const UserActivityRowItem = (props: Props) => {
  const { item } = props;

  const nftType = item.type as TNFTType;

  let imageUrl = '';
  if (item.tokenId) {
    imageUrl =
      nftType === NFTType.LAND
        ? coreUtils.getImageByTokenId(new BN(item.tokenId), nftType)
        : coreUtils.getImageByTemplateId(getTemplateId(new BN(item.tokenId)), nftType);
  } else {
    imageUrl = '#';
  }

  return (
    <HStack>
      {nftType === NFTType.LAND ? (
        <Image {...styles.landImage} src={imageUrl} />
      ) : (
        <Image {...styles.image} src={imageUrl} />
      )}
      <div>
        <Text>{item?.metadata?.name}</Text>
      </div>
    </HStack>
  );
};

const styles = {
  image: {
    height: '50px',
    objectFit: 'contain' as 'contain',
  },
  landImage: {
    height: '50px',
    objectFit: 'contain' as 'contain',
    borderRadius: '4px',
    border: '1px solid white',
  },
};

export default UserActivityRowItem;
