import { IGeneMetadata, ILandMetadata, IMiningToolMetadata } from 'src/gql/types';
import { IFilterConfigs } from 'src/redux/app/types';
import Web3 from 'web3';
import { IRequestFilterBase } from './types';

export const DefaultOwnerAddress = '0x00000000';

export enum RoutePath {
  ROOT = '/',
  DASHBOARD = '/dashboard',
  GENE = '/gene',
  LAND = '/land',
  MINING_TOOL = '/mining_tool',
  TOOL_MATERIAL = '/material',
  GACHA_BOX = '/gacha_box',
  LOGIN = '/login',
  PROFILE = '/profile',
  USER_ACTIVITY = '/activity',
  INVENTORY = '/inventory',
  INVENTORY_GENE = '/inventory/gene',
  INVENTORY_LAND = '/inventory/land',
  INVENTORY_MINING_TOOL = '/inventory/mining_tool',
  INVENTORY_TOOL_MATERIAL = '/inventory/material',
  INVENTORY_GACHA_BOX = '/inventory/gacha_box',
}

export const HEADER_HEIGHT = '75px';
export const SECONDARY_HEADER_HEIGHT = '51px';
export const MOBILE_SECONDARY_HEADER_HEIGHT = '65px';

export const CryptoDecimal = 18;
export const MaxPrecision = 7;

export enum SortType {
  DEFAULT = 'default',
  ID_ASC = 'idAsc',
  ID_DESC = 'idDesc',
  NEWEST = 'newest',
  RARITY_ASC = 'rarityAsc',
  RARITY_DESC = 'rarityDesc',
  PRICE_ASC = 'priceAsc',
  PRICE_DESC = 'priceDesc',
  MP_ASC = 'mpAsc',
  MP_DESC = 'mpDesc',
  CD_ASC = 'cdAsc',
  CD_DESC = 'cdDesc',
  LUCK_ASC = 'luckAsc',
  LUCK_DESC = 'luckDesc',
}

export const SortLabel = {
  [SortType.ID_ASC]: 'Term:SortType.ID_ASC',
  [SortType.ID_DESC]: 'Term:SortType.ID_DESC',
  [SortType.NEWEST]: 'Term:SortType.NEWEST',
  [SortType.RARITY_ASC]: 'Term:SortType.RARITY_ASC',
  [SortType.RARITY_DESC]: 'Term:SortType.RARITY_DESC',
  [SortType.PRICE_ASC]: 'Term:SortType.PRICE_ASC',
  [SortType.PRICE_DESC]: 'Term:SortType.PRICE_DESC',
  [SortType.MP_ASC]: 'Term:SortType.MP_ASC',
  [SortType.MP_DESC]: 'Term:SortType.MP_DESC',
  [SortType.CD_ASC]: 'Term:SortType.CD_ASC',
  [SortType.CD_DESC]: 'Term:SortType.CD_DESC',
  [SortType.LUCK_ASC]: 'Term:SortType.LUCK_ASC',
  [SortType.LUCK_DESC]: 'Term:SortType.LUCK_DESC',
  [SortType.DEFAULT]: 'Term:SortType.DEFAULT',
};

export const SortByType = {
  [SortType.ID_ASC]: {
    key: 'index',
    order: 'asc',
  },
  [SortType.ID_DESC]: {
    key: 'index',
    order: 'desc',
  },
  [SortType.NEWEST]: {
    key: 'updatedAt',
    order: 'desc',
  },
  [SortType.RARITY_ASC]: {
    key: 'rarity',
    order: 'asc',
  },
  [SortType.RARITY_DESC]: {
    key: 'rarity',
    order: 'desc',
  },
  [SortType.MP_ASC]: {
    key: 'mp',
    order: 'asc',
  },
  [SortType.MP_DESC]: {
    key: 'mp',
    order: 'desc',
  },
  [SortType.CD_ASC]: {
    key: 'cd',
    order: 'asc',
  },
  [SortType.CD_DESC]: {
    key: 'cd',
    order: 'desc',
  },
  [SortType.LUCK_ASC]: {
    key: 'luck',
    order: 'asc',
  },
  [SortType.LUCK_DESC]: {
    key: 'luck',
    order: 'desc',
  },
  [SortType.PRICE_ASC]: {
    key: 'price',
    order: 'asc',
  },
  [SortType.PRICE_DESC]: {
    key: 'price',
    order: 'desc',
  },
};

export const StandardSortTypes: SortType[] = [
  SortType.DEFAULT,
  SortType.ID_ASC,
  SortType.ID_DESC,
  SortType.NEWEST,
];

export const StandardSaleSortTypes: SortType[] = [
  SortType.DEFAULT,
  SortType.ID_ASC,
  SortType.ID_DESC,
  SortType.PRICE_ASC,
  SortType.PRICE_DESC,
  SortType.NEWEST,
];

export const LandSortTypes: SortType[] = [
  SortType.DEFAULT,
  SortType.ID_ASC,
  SortType.ID_DESC,
  SortType.MP_ASC,
  SortType.MP_DESC,
  SortType.CD_ASC,
  SortType.CD_DESC,
  SortType.LUCK_ASC,
  SortType.LUCK_DESC,
  SortType.NEWEST,
];

export const LandSaleSortTypes: SortType[] = [
  SortType.DEFAULT,
  SortType.ID_ASC,
  SortType.ID_DESC,
  SortType.PRICE_ASC,
  SortType.PRICE_DESC,
  SortType.MP_ASC,
  SortType.MP_DESC,
  SortType.CD_ASC,
  SortType.CD_DESC,
  SortType.LUCK_ASC,
  SortType.LUCK_DESC,
  SortType.NEWEST,
];

export const MiningToolSortTypes: SortType[] = [
  SortType.DEFAULT,
  SortType.ID_ASC,
  SortType.ID_DESC,
  SortType.RARITY_ASC,
  SortType.RARITY_DESC,
  SortType.MP_ASC,
  SortType.MP_DESC,
  SortType.CD_ASC,
  SortType.CD_DESC,
  SortType.LUCK_ASC,
  SortType.LUCK_DESC,
  SortType.NEWEST,
];

export const MiningToolSaleSortTypes: SortType[] = [
  SortType.DEFAULT,
  SortType.ID_ASC,
  SortType.ID_DESC,
  SortType.PRICE_ASC,
  SortType.PRICE_DESC,
  SortType.RARITY_ASC,
  SortType.RARITY_DESC,
  SortType.MP_ASC,
  SortType.MP_DESC,
  SortType.CD_ASC,
  SortType.CD_DESC,
  SortType.LUCK_ASC,
  SortType.LUCK_DESC,
  SortType.NEWEST,
];

export const GeneSortTypes: SortType[] = [
  SortType.DEFAULT,
  SortType.ID_ASC,
  SortType.ID_DESC,
  SortType.RARITY_ASC,
  SortType.RARITY_DESC,
  SortType.NEWEST,
];

export const GeneSaleSortTypes: SortType[] = [
  SortType.DEFAULT,
  SortType.ID_ASC,
  SortType.ID_DESC,
  SortType.PRICE_ASC,
  SortType.PRICE_DESC,
  SortType.RARITY_ASC,
  SortType.RARITY_DESC,
  SortType.NEWEST,
];

export enum SaleType {
  ALL = 'all',
  SALE = 'sale',
}

export const SaleLabel = {
  [SaleType.ALL]: 'Component:SaleSelect.All',
  [SaleType.SALE]: 'Component:SaleSelect.ForSale',
};

export const DefaultLimit = 10;

export enum QueryString {
  SEARCH_QUERY = 'q',
  SORT = 'sort',
  PAGE = 'page',
  CATEGORY = 'cat',
  RARITY = 'rarity',
  MINING_POWER = 'mp', // Mining power
  COOLDOWN = 'cd', // Cooldown
  LUCK = 'luck', // Luck
  SALE_TYPE = 'saleType',
  PART = 'part',
  GENE_CLASS = 'geneClass',
}

export enum NFTType {
  LAND = 'Land',
  MINING_TOOL = 'Tool',
  GENE = 'Gene',
  GACHA = 'Gacha',
  TOOL_MATERIAL = 'ToolMaterial',
}

export enum FixedPriceSaleStatus {
  CANCELED = 'CANCELED',
  AVAILABLE = 'AVAILABLE',
  BOUGHT = 'BOUGHT',
}

export enum MetaMaskModalType {
  METAMASK_NOT_INSTALL = 'metamask_not_install',
  METAMASK_NOT_UNLOCK = 'metamask_not_unlock',
  METAMASK_NOT_CONNECT_BSC = 'metamask_not_connect_bsc',
}

export enum SaleTransactionEvent {
  SalePriceChanged = 'SalePriceChanged',
  SaleCanceled = 'SaleCanceled',
  SaleAdded = 'SaleAdded',
  SaleDone = 'SaleDone',
  Approval = 'Approval',
  TransferSingle = 'TransferSingle',
  TransferBatch = 'TransferBatch',
}

export const DefaultMiningToolMetadata: IMiningToolMetadata = {
  templateId: 10000,
  name: '',
  cat: 0,
  rarity: 0,
  supply: 0,
  weight: 0,
  cd: 0,
  mp: 0,
  luck: 0,
};

export const DefaultLandMetadata: ILandMetadata = {
  templateId: 10000,
  name: '',
  supply: 0,
  weight: 0,
  cd: 0,
  mp: 0,
  luck: 0,
};

export const DefaultGeneMetadata: IGeneMetadata = {
  templateId: 10000,
  name: '',
  supply: 0,
  weight: 0,
  geneClass: 0,
  part: 0,
  rarity: 0,
};

export enum ToolCategoryType { // 1: mining power, 2: luck, 4: hybrid
  MINING_POWER = 1,
  LUCK = 2,
  HYBRID = 4,
}

export enum RarityType {
  COMMON = 1,
  UNCOMMON = 2,
  RARE = 3,
  EPIC = 4,
  LEGENDARY = 5,
}

export enum GenePartType {
  BODY = 1,
  SUB = 2,
  HEAD = 3,
  LEFT_ARM = 4,
  RIGHT_ARM = 5,
}

export enum GeneClassType {
  GENERIC = 1,
  CRUSADER = 2,
  WIZARD = 3,
  ASSASSIN = 4,
  SAINT = 5,
  SHAMAN = 6,
}

export const DefaultMiningToolFilterBase: IRequestFilterBase = {
  [QueryString.CATEGORY]: {
    value: [1, 2, 4],
  },
  [QueryString.RARITY]: {
    value: [1, 2, 3, 4, 5],
  },
  [QueryString.MINING_POWER]: {
    value: [0, 6.5],
    step: 0.65,
    min: 0,
    max: 6.5,
    minStepsBetweenThumbs: 1,
    unit: '%',
  },
  [QueryString.COOLDOWN]: {
    value: [180, 2100],
    step: 192,
    min: 180,
    max: 2100,
    minStepsBetweenThumbs: 1,
    unit: 's',
  },
  [QueryString.LUCK]: {
    value: [0, 25],
    step: 2.5,
    min: 0,
    max: 25,
    minStepsBetweenThumbs: 1,
    unit: '%',
  },
};

export const DefaultLandFilterBase: IRequestFilterBase = {
  [QueryString.MINING_POWER]: {
    value: [0.4, 3],
    step: 0.26,
    min: 0.4,
    max: 3,
    minStepsBetweenThumbs: 1,
    unit: null,
  },
  [QueryString.COOLDOWN]: {
    value: [1.5, 5],
    step: 0.35,
    min: 1.5,
    max: 5,
    minStepsBetweenThumbs: 1,
    unit: null,
  },
  [QueryString.LUCK]: {
    value: [0.3, 3],
    step: 0.27,
    min: 0.3,
    max: 3,
    minStepsBetweenThumbs: 1,
    unit: null,
  },
};

export const DefaultGeneFilterBase: IRequestFilterBase = {
  [QueryString.GENE_CLASS]: {
    value: [1, 2, 3, 4, 5],
  },
  [QueryString.PART]: {
    value: [1, 2, 3, 4, 5],
  },
  [QueryString.RARITY]: {
    value: [1, 2, 3, 4, 5],
  },
};
export const DefaultFilterConfigs: IFilterConfigs = {
  land: DefaultLandFilterBase,
  miningTool: DefaultMiningToolFilterBase,
  gene: DefaultGeneFilterBase,
};

export enum SupportedLanguage {
  VI = 'vi',
  EN = 'en',
}

export enum SupportedTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum NFTFormat {
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
}

export enum MenuLabel {
  DASHBOARD = 'PageName:Dashboard',
  MARKETPLACE = 'PageName:Marketplace',
  INVENTORY = 'PageName:Inventory',
  ACTIVITY = 'PageName:Activity',
}

export enum NFTMenuLabel {
  GENE = 'PageName:Gene',
  LAND = 'PageName:Land',
  MINING_TOOL = 'PageName:MiningTool',
  TOOL_MATERIAL = 'PageName:ToolMaterial',
  GACHA_BOX = 'PageName:GachaBox',
}

export const ff32 = Web3.utils.toBN('0xFFFFFFFF');
export const ff24 = Web3.utils.toBN('0xFFFFFF');
export const ff4 = Web3.utils.toBN('0xF');
export const ff64 = Web3.utils.toBN('0xFFFFFFFFFFFFFFFF');
