import React from 'react';
import { DashboardLayout } from 'src/layouts';
import { FixedPriceSaleNFTDetail } from 'src/features/nftDetail';
import { NFTType } from 'src/globals/constants';

const Detail = () => {
  return (
    <DashboardLayout>
      <FixedPriceSaleNFTDetail nftType={NFTType.LAND} />
    </DashboardLayout>
  );
};

export default Detail;
