import { useCallback, useState } from 'react';
import MetamaskProvider from 'src/metamask-provider';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';

const useAddNFTToFixedPriceSaleMarket = (nftType: TNFTType) => {
  const [error, setError] = useState<string | undefined>();
  const [isAddingNFTToFixedPriceSaleMarket, setIsAddingNFTToFixedPriceSaleMarket] = useState<
    boolean | undefined
  >();
  const onAddNFTToFixedPriceSaleMarket = useCallback(
    (
      tokenId: string,
      price: string,
      amount: string,
      onTransactionSubmitted?: (txid: string) => void
    ) => {
      setError(undefined);
      setIsAddingNFTToFixedPriceSaleMarket(true);
      MetamaskProvider.addNFTToFixedPriceSaleMarket(
        coreUtils.getNFTContractNameByNftType(nftType),
        tokenId,
        price,
        amount
      )
        .then((receipt) => {
          if (onTransactionSubmitted) {
            onTransactionSubmitted(receipt.transactionHash);
          }
        })
        .catch((error) => {
          setError(error.message);
          console.error(error);
        })
        .finally(() => {
          setIsAddingNFTToFixedPriceSaleMarket(false);
        });
    },
    [nftType, setIsAddingNFTToFixedPriceSaleMarket]
  );

  return [isAddingNFTToFixedPriceSaleMarket, onAddNFTToFixedPriceSaleMarket, error];
};

export default useAddNFTToFixedPriceSaleMarket;
