import { TNFTType } from '../types';
import { NFTType } from '../../globals/constants';
import { getMiningToolMetadataFragment } from './miningToolMetadata';
import { getGeneMetadataFragment } from './geneMetadata';
import { getLandMetadataFragment } from './landMetadata';

export const NFT_METADATA_KEY = 'nftMetadata';
export const getNFTMetadataFragment = (nftType: TNFTType, key = NFT_METADATA_KEY) => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return getMiningToolMetadataFragment(key);
    case NFTType.GENE:
      return getGeneMetadataFragment(key);
    case NFTType.LAND:
      return getLandMetadataFragment(key);
  }

  return '';
};
