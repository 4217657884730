import { Dispatch } from 'redux';
import isNil from 'lodash/isNil';
import * as GQLApi from 'src/gql';
import {
  IAppVariable,
  IFilter,
  IFixedPriceSaleNFT,
  IFixedPriceSaleNFTVariable,
  IListFixedPriceSaleNFTResult,
  IOrderByKeyInput,
  TNFTType,
} from 'src/gql/types';
import { FixedPriceSaleStatus, NFTType } from 'src/globals/constants';
import { getFilterVariableByType, getOrderByVariableByType } from 'src/gql/common';
import * as Types from './types';

const getMiningToolsRequest = () => {
  return {
    type: Types.GET_MINING_TOOLS_REQUEST,
  };
};

const getMiningToolsSuccess = (payload: IListFixedPriceSaleNFTResult) => {
  return {
    type: Types.GET_MINING_TOOLS_SUCCESS,
    payload,
  };
};

const getMiningToolsFailure = (errorMessage: string) => {
  return {
    type: Types.GET_MINING_TOOLS_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getMiningTools = (variables: IFixedPriceSaleNFTVariable) => {
  return (dispatch: Dispatch) => {
    dispatch(getMiningToolsRequest());
    return GQLApi.getFixedPriceSalesByNFTType(NFTType.MINING_TOOL, variables)
      .then((result) => {
        return dispatch(getMiningToolsSuccess(result));
      })
      .catch((e) => dispatch(getMiningToolsFailure(e)));
  };
};

const getLandsRequest = () => {
  return {
    type: Types.GET_MINING_TOOLS_REQUEST,
  };
};

const getLandsSuccess = (payload: IListFixedPriceSaleNFTResult) => {
  return {
    type: Types.GET_LANDS_SUCCESS,
    payload,
  };
};

const getLandsFailure = (errorMessage: string) => {
  return {
    type: Types.GET_LANDS_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getLands = (variables: IFixedPriceSaleNFTVariable) => {
  return (dispatch: Dispatch) => {
    dispatch(getLandsRequest());
    return GQLApi.getFixedPriceSalesByNFTType(NFTType.LAND, variables)
      .then((result) => {
        return dispatch(getLandsSuccess(result));
      })
      .catch((e) => dispatch(getLandsFailure(e)));
  };
};

const getGenesRequest = () => {
  return {
    type: Types.GET_GENES_REQUEST,
  };
};

const getGenesSuccess = (payload: IListFixedPriceSaleNFTResult) => {
  return {
    type: Types.GET_GENES_SUCCESS,
    payload,
  };
};

const getGenesFailure = (errorMessage: string) => {
  return {
    type: Types.GET_GENES_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getGenes = (variables: IFixedPriceSaleNFTVariable) => {
  return (dispatch: Dispatch) => {
    dispatch(getGenesRequest());
    return GQLApi.getFixedPriceSalesByNFTType(NFTType.GENE, variables)
      .then((result) => {
        return dispatch(getGenesSuccess(result));
      })
      .catch((e) => dispatch(getGenesFailure(e)));
  };
};

const getGachaBoxesRequest = () => {
  return {
    type: Types.GET_GACHA_BOXES_REQUEST,
  };
};

const getGachaBoxesSuccess = (payload: IListFixedPriceSaleNFTResult) => {
  return {
    type: Types.GET_GACHA_BOXES_SUCCESS,
    payload,
  };
};

const getGachaBoxesFailure = (errorMessage: string) => {
  return {
    type: Types.GET_GACHA_BOXES_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getGachaBoxes = (variables: IFixedPriceSaleNFTVariable) => {
  return (dispatch: Dispatch) => {
    dispatch(getGachaBoxesRequest());
    return GQLApi.getFixedPriceSalesByNFTType(NFTType.GACHA, variables)
      .then((result) => {
        return dispatch(getGachaBoxesSuccess(result));
      })
      .catch((e) => dispatch(getGachaBoxesFailure(e)));
  };
};

const getToolMaterialsRequest = () => {
  return {
    type: Types.GET_TOOL_MATERIALS_REQUEST,
  };
};

const getToolMaterialsSuccess = (payload: IListFixedPriceSaleNFTResult) => {
  return {
    type: Types.GET_TOOL_MATERIALS_SUCCESS,
    payload,
  };
};

const getToolMaterialsFailure = (errorMessage: string) => {
  return {
    type: Types.GET_TOOL_MATERIALS_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getToolMaterials = (variables: IFixedPriceSaleNFTVariable) => {
  return (dispatch: Dispatch) => {
    dispatch(getToolMaterialsRequest());
    return GQLApi.getFixedPriceSalesByNFTType(NFTType.TOOL_MATERIAL, variables)
      .then((result) => {
        return dispatch(getToolMaterialsSuccess(result));
      })
      .catch((e) => dispatch(getToolMaterialsFailure(e)));
  };
};
const getCurrentNFTRequest = () => {
  return {
    type: Types.GET_CURRENT_NFT_REQUEST,
  };
};

const getCurrentNFTSuccess = (payload: IFixedPriceSaleNFT) => {
  return {
    type: Types.GET_CURRENT_NFT_SUCCESS,
    payload,
  };
};

const getCurrentNFTFailure = (errorMessage: string) => {
  return {
    type: Types.GET_CURRENT_NFT_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getCurrentNFT = (nftType: TNFTType, saleId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(getCurrentNFTRequest());
    return GQLApi.getFixedPriceSaleDetailByNFTType(nftType, saleId)
      .then((result) => {
        return dispatch(getCurrentNFTSuccess(result));
      })
      .catch((e) => {
        console.log('err', e);
        dispatch(getCurrentNFTFailure(e));
      });
  };
};

export const getAvailableFixedPriceSaleNFTsForSale = (
  nftType: TNFTType,
  variable: IAppVariable
) => {
  const { filter: filterByNFTType, orderBy: orderByNFTType, ...remainingVariables } = variable;

  const orderBy = isNil(orderByNFTType)
    ? undefined
    : getOrderByVariableByType(nftType, orderByNFTType as IOrderByKeyInput);
  const filter = isNil(filterByNFTType)
    ? undefined
    : getFilterVariableByType(nftType, filterByNFTType as IFilter);
  const newVariable: IFixedPriceSaleNFTVariable = {
    ...remainingVariables,
    filter,
    orderBy,
    status: FixedPriceSaleStatus.AVAILABLE,
    type: nftType,
  };
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return getMiningTools(newVariable);
    case NFTType.LAND:
      return getLands(newVariable);
    case NFTType.GENE:
      return getGenes(newVariable);
    case NFTType.GACHA:
      return getGachaBoxes(newVariable);
    case NFTType.TOOL_MATERIAL:
      return getToolMaterials(newVariable);
  }
};

const getSaleHistoriesRequest = (tokenId: string, tokenType: string) => {
  return {
    type: Types.GET_SALE_HISTORIES_REQUEST,
    payload: {
      tokenId,
      tokenType,
    },
  };
};

const getSaleHistoriesSuccess = (payload: IListFixedPriceSaleNFTResult) => {
  return {
    type: Types.GET_SALE_HISTORIES_SUCCESS,
    payload,
  };
};

const getSaleHistoriesFailure = (errorMessage: string) => {
  return {
    type: Types.GET_SALE_HISTORIES_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getSaleHistories = (tokenType: TNFTType, tokenId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(getSaleHistoriesRequest(tokenId, tokenType));
    return GQLApi.getSaleHistories(tokenType, tokenId)
      .then((result) => {
        return dispatch(getSaleHistoriesSuccess(result));
      })
      .catch((e) => {
        console.log('err', e);
        dispatch(getSaleHistoriesFailure(e));
      });
  };
};
