import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import theme from 'src/shared/theme';
import { store, persistor } from 'src/redux';
import { apolloClient } from 'src/gql';
import * as Locales from 'src/locales';
import { ErrorModal } from 'src/features/errorModal';
import { AppConfig } from 'src/features/appConfig/components';
import { DateAndTime } from 'src/features/dateAndTime';
import RootRouter from './RootRouter';

Locales.initLocales();

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ChakraProvider theme={theme}>
            <AppConfig />
            <DateAndTime />
            <RootRouter />
            <ErrorModal />
          </ChakraProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
