import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardLayout } from 'src/layouts';
import { Filter } from 'src/features/filter';
import { NFTType } from 'src/globals/constants';
import { AppAction, RootReducerState } from 'src/redux';
import { FixedPriceSaleNFTList } from 'src/features/nftList';
import { useIsDesktop } from 'src/hooks';
import MarketHeader from '../../components/MarketHeader';

const Land = () => {
  const filterConfigs = useSelector((state: RootReducerState) => state.app.filterConfigs);
  const dispatch = useDispatch();
  const onToggleFilterOnMobile = () => dispatch(AppAction.toggleSideBar());
  const isDesktop = useIsDesktop();

  const renderSidebarContent = () => (
    <Filter
      showBackButton={!isDesktop}
      filterBase={filterConfigs.land}
      onBack={onToggleFilterOnMobile}
    />
  );

  return (
    <DashboardLayout renderSidebarContent={renderSidebarContent}>
      <Flex flex={{ base: 1 }} p={{ base: 2, md: 4 }} flexDirection={'column'}>
        <MarketHeader nftType={NFTType.LAND} />
        <FixedPriceSaleNFTList nftType={NFTType.LAND} />
      </Flex>
    </DashboardLayout>
  );
};

export default Land;
