import { Dispatch } from 'redux';
import isNil from 'lodash/isNil';
import * as GQLApi from 'src/gql';
import {
  INFTVariable,
  INFT,
  TNFTType,
  INFTsResult,
  IAppVariable,
  IOrderByKeyInput,
} from 'src/gql/types';
import { NFTType } from 'src/globals/constants';
import { getOrderByVariableByType } from 'src/gql/common';
import MetamaskProvider from '../../metamask-provider';
import * as Types from './types';

const getMyMiningToolsRequest = () => {
  return {
    type: Types.GET_MY_MINING_TOOLS_REQUEST,
  };
};

const getMyMiningToolsSuccess = (payload: INFTsResult) => {
  return {
    type: Types.GET_MY_MINING_TOOLS_SUCCESS,
    payload,
  };
};

const getMyMiningToolsFailure = (errorMessage: string) => {
  return {
    type: Types.GET_MY_MINING_TOOLS_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getMyMiningTools = (variables: INFTVariable) => {
  return (dispatch: Dispatch) => {
    dispatch(getMyMiningToolsRequest());
    return GQLApi.getMyNFTsByNFTType(NFTType.MINING_TOOL, variables)
      .then((result) => {
        return dispatch(getMyMiningToolsSuccess(result));
      })
      .catch((e) => dispatch(getMyMiningToolsFailure(e)));
  };
};

const getCurrentMiningToolsRequest = () => {
  return {
    type: Types.GET_CURRENT_MINING_TOOLS_REQUEST,
  };
};

const getCurrentMiningToolsSuccess = (payload: Array<string>) => {
  return {
    type: Types.GET_CURRENT_MINING_TOOLS_SUCCESS,
    payload,
  };
};

const getCurrentMiningToolsFailure = (errorMessage: string) => {
  return {
    type: Types.GET_CURRENT_MINING_TOOLS_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getCurrentMiningTools = () => {
  return (dispatch: Dispatch) => {
    dispatch(getCurrentMiningToolsRequest());

    return MetamaskProvider.getCurrentMiningTools()
      .then((data) => {
        return dispatch(getCurrentMiningToolsSuccess(data));
      })
      .catch((e) => dispatch(getCurrentMiningToolsFailure(e.message)));
  };
};

const getLandsRequest = () => {
  return {
    type: Types.GET_MY_LANDS_REQUEST,
  };
};

const getLandsSuccess = (payload: INFTsResult) => {
  return {
    type: Types.GET_MY_LANDS_SUCCESS,
    payload,
  };
};

const getLandsFailure = (errorMessage: string) => {
  return {
    type: Types.GET_MY_LANDS_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getMyLands = (variables: INFTVariable) => {
  return (dispatch: Dispatch) => {
    dispatch(getLandsRequest());
    return GQLApi.getMyNFTsByNFTType(NFTType.LAND, variables)
      .then((result) => {
        return dispatch(getLandsSuccess(result));
      })
      .catch((e) => dispatch(getLandsFailure(e)));
  };
};

const getGenesRequest = () => {
  return {
    type: Types.GET_MY_GENES_REQUEST,
  };
};

const getGenesSuccess = (payload: INFTsResult) => {
  return {
    type: Types.GET_MY_GENES_SUCCESS,
    payload,
  };
};

const getGenesFailure = (errorMessage: string) => {
  return {
    type: Types.GET_MY_GENES_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getMyGenes = (variables: INFTVariable) => {
  return (dispatch: Dispatch) => {
    dispatch(getGenesRequest());
    return GQLApi.getMyNFTsByNFTType(NFTType.GENE, variables)
      .then((result) => {
        return dispatch(getGenesSuccess(result));
      })
      .catch((e) => dispatch(getGenesFailure(e)));
  };
};

const getGachaBoxesRequest = () => {
  return {
    type: Types.GET_MY_GACHA_BOXES_REQUEST,
  };
};

const getGachaBoxesSuccess = (payload: INFTsResult) => {
  return {
    type: Types.GET_MY_GACHA_BOXES_SUCCESS,
    payload,
  };
};

const getGachaBoxesFailure = (errorMessage: string) => {
  return {
    type: Types.GET_MY_GACHA_BOXES_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getMyGachaBoxes = (variables: INFTVariable) => {
  return (dispatch: Dispatch) => {
    dispatch(getGachaBoxesRequest());
    return GQLApi.getMyNFTsByNFTType(NFTType.GACHA, variables)
      .then((result) => {
        return dispatch(getGachaBoxesSuccess(result));
      })
      .catch((e) => dispatch(getGachaBoxesFailure(e)));
  };
};

const getToolMaterialsRequest = () => {
  return {
    type: Types.GET_MY_TOOL_MATERIALS_REQUEST,
  };
};

const getToolMaterialsSuccess = (payload: INFTsResult) => {
  return {
    type: Types.GET_MY_TOOL_MATERIALS_SUCCESS,
    payload,
  };
};

const getToolMaterialsFailure = (errorMessage: string) => {
  return {
    type: Types.GET_MY_TOOL_MATERIALS_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getMyToolMaterials = (variables: INFTVariable) => {
  return (dispatch: Dispatch) => {
    dispatch(getToolMaterialsRequest());
    return GQLApi.getMyNFTsByNFTType(NFTType.TOOL_MATERIAL, variables)
      .then((result) => {
        return dispatch(getToolMaterialsSuccess(result));
      })
      .catch((e) => dispatch(getToolMaterialsFailure(e)));
  };
};

const getCurrentMyNFTRequest = () => {
  return {
    type: Types.GET_CURRENT_MY_NFT_REQUEST,
  };
};

const getCurrentMyNFTSuccess = (payload: INFT) => {
  return {
    type: Types.GET_CURRENT_MY_NFT_SUCCESS,
    payload,
  };
};

const getCurrentMyNFTFailure = (errorMessage: string) => {
  return {
    type: Types.GET_CURRENT_MY_NFT_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getCurrentMyNFT = (tokenType: TNFTType, tokenId: string, ownerId: string = '') => {
  return (dispatch: Dispatch) => {
    dispatch(getCurrentMyNFTRequest());
    return GQLApi.getMyNFTDetailByNFTType(tokenType, { tokenId, owner: ownerId })
      .then((result) => {
        return dispatch(getCurrentMyNFTSuccess(result));
      })
      .catch((e) => {
        console.log('err', e);
        dispatch(getCurrentMyNFTFailure(e));
      });
  };
};

export const getMyNFTs = (nftType: TNFTType, variables: IAppVariable, ownerId: string = '') => {
  const { orderBy: orderByNFTType, ...remainingVariables } = variables;

  const orderBy = isNil(orderByNFTType)
    ? undefined
    : getOrderByVariableByType(nftType, orderByNFTType as IOrderByKeyInput);
  const newVariables: INFTVariable = {
    ...remainingVariables,
    orderBy,
    owner: ownerId,
  };
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return getMyMiningTools(newVariables);
    case NFTType.LAND:
      return getMyLands(newVariables);
    case NFTType.GENE:
      return getMyGenes(newVariables);
    case NFTType.GACHA:
      return getMyGachaBoxes(newVariables);
    case NFTType.TOOL_MATERIAL:
      return getMyToolMaterials(newVariables);
  }
};
