import { useCallback, useState } from 'react';
import MetamaskProvider, { metaMaskCommon } from 'src/metamask-provider';
import { ISaleInfoFromMetaMask, TokenFormat } from 'src/metamask-provider/type';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';

const useGetFixedPriceSaleInfoByTokenId = (nftType: TNFTType) => {
  const [isGettingSaleInfo, setIsGettingSaleInfo] = useState<boolean | undefined>();
  const [saleInfo, setSaleInfo] = useState<
    ISaleInfoFromMetaMask | ISaleInfoFromMetaMask[] | undefined
  >();
  const [currentTokenId, setCurrentTokenId] = useState<string | undefined>();
  const getFixedPriceSaleInfoByTokenId = useCallback(
    (tokenId?: string) => {
      setCurrentTokenId(tokenId);
      if (tokenId) {
        setIsGettingSaleInfo(true);
        const nftContractName = coreUtils.getNFTContractNameByNftType(nftType);
        const tokenFormat = metaMaskCommon.getTokenFormatOfNFTByNftContractName(nftContractName);
        const getSaleInfoFunction =
          tokenFormat === TokenFormat.ERC721
            ? MetamaskProvider.getFixedPriceSaleInfoByTokenId721
            : MetamaskProvider.getFixedPriceSaleInfoByTokenId1155;
        getSaleInfoFunction(nftContractName, tokenId)
          .then((saleInfo) => {
            return setSaleInfo(saleInfo);
          })
          .catch(console.error)
          .finally(() => setIsGettingSaleInfo(false));
      } else {
        setSaleInfo(undefined);
      }
    },
    [nftType, setIsGettingSaleInfo]
  );

  return [isGettingSaleInfo, currentTokenId ? saleInfo : undefined, getFixedPriceSaleInfoByTokenId];
};

export default useGetFixedPriceSaleInfoByTokenId;
