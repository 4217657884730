import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { InventoryAction, RootReducerState } from 'src/redux';
import { coreUtils } from 'src/common';
import { IAppVariable, INFT, TNFTType } from 'src/gql/types';
import { NFTType } from 'src/globals/constants';
import { getEmptyMessageByNFTType, getInventoryPathByNFTType } from '../common';
import Listing from './Listing';
import NFTItem from './NFTItem';

interface OwnProps {
  ownerId?: string;
  nftType: TNFTType;
}

interface StateProps {
  isGettingNFTs: boolean;
  listOfNFTs: Array<INFT>;
  totalOfNFTs: number;
}

interface DispatchProps {
  onGetNFTs: (variables: IAppVariable) => void;
}

type MyNFTListProps = OwnProps & StateProps & DispatchProps;

const MyNFTList = (props: MyNFTListProps) => {
  const { isGettingNFTs, listOfNFTs, onGetNFTs, ownerId, nftType } = props;
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = queryString.parse(search);
    const variables = coreUtils.standardizeVariablesForQuery(queryParams);
    onGetNFTs(variables);
  }, [search, ownerId, history, onGetNFTs]);

  useEffect(() => {
    nftType === NFTType.MINING_TOOL && dispatch(InventoryAction.getCurrentMiningTools());
  }, [nftType, dispatch]);

  return (
    <Listing
      emptyDescription={t(getEmptyMessageByNFTType(nftType))}
      loading={isGettingNFTs}
      data={listOfNFTs}
      renderItem={({ item }) => (
        <NFTItem
          key={item.tokenId}
          item={item as INFT}
          nftType={nftType}
          rootPath={getInventoryPathByNFTType(nftType)}
        />
      )}
    />
  );
};

const mapStateToProps = (state: RootReducerState, ownProps: OwnProps) => {
  let listOfNFTs: Array<INFT>;
  let isGettingNFTs: boolean;
  let totalOfNFTs: number;

  const { inventory } = state;
  const {
    listOfMyMiningTools,
    isGettingCurrentMiningTools,
    isGettingMyMiningTools,
    totalOfMyMiningTools,
    listOfMyLands,
    isGettingMyLands,
    totalOfMyLands,
    listOfMyGenes,
    isGettingMyGenes,
    totalOfMyGenes,
    listOfMyGachaBoxes,
    isGettingMyGachaBoxes,
    totalOfMyGachaBoxes,
    listOfMyToolMaterials,
    isGettingMyToolMaterials,
    totalOfMyToolMaterials,
  } = inventory;

  switch (ownProps.nftType) {
    case NFTType.LAND: {
      listOfNFTs = listOfMyLands;
      isGettingNFTs = isGettingMyLands;
      totalOfNFTs = totalOfMyLands;
      break;
    }
    case NFTType.GENE: {
      listOfNFTs = listOfMyGenes;
      isGettingNFTs = isGettingMyGenes;
      totalOfNFTs = totalOfMyGenes;
      break;
    }
    case NFTType.MINING_TOOL: {
      listOfNFTs = listOfMyMiningTools;
      isGettingNFTs = isGettingMyMiningTools && isGettingCurrentMiningTools;
      totalOfNFTs = totalOfMyMiningTools;
      break;
    }
    case NFTType.GACHA: {
      listOfNFTs = listOfMyGachaBoxes;
      isGettingNFTs = isGettingMyGachaBoxes;
      totalOfNFTs = totalOfMyGachaBoxes;
      break;
    }
    case NFTType.TOOL_MATERIAL: {
      listOfNFTs = listOfMyToolMaterials;
      isGettingNFTs = isGettingMyToolMaterials;
      totalOfNFTs = totalOfMyToolMaterials;
      break;
    }
    default: {
      listOfNFTs = [];
      isGettingNFTs = false;
      totalOfNFTs = 0;
      break;
    }
  }
  return {
    listOfNFTs,
    isGettingNFTs,
    totalOfNFTs,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    onGetNFTs: (variables: IAppVariable) =>
      dispatch<any>(InventoryAction.getMyNFTs(ownProps.nftType, variables, ownProps.ownerId)),
  };
};
export default connect<StateProps, DispatchProps, OwnProps, RootReducerState>(
  mapStateToProps,
  mapDispatchToProps
)(MyNFTList);
