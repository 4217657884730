import React, { useCallback } from 'react';
import { ButtonProps, Image, forwardRef } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import ButtonWithHover from 'src/components/Button/ButtonWithHover';
import { AppSizes, Resources } from 'src/shared';
import { InventoryAction, RootReducerState } from 'src/redux';
import { InventoryState } from 'src/redux/inventory/types';
import { coreUtils } from 'src/common';
import { NFTType } from 'src/globals/constants';
import { TNFTType } from 'src/gql/types';

const PredefinedStateAndAction = {
  [NFTType.MINING_TOOL]: {
    loading: 'isGettingMyMiningTools',
  },
  [NFTType.LAND]: {
    loading: 'isGettingMyLands',
  },
  [NFTType.GENE]: {
    loading: 'isGettingMyGenes',
  },
  [NFTType.GACHA]: {
    loading: 'isGettingMyGachaBoxes',
  },
  [NFTType.TOOL_MATERIAL]: {
    loading: 'isGettingMyToolMaterials',
  },
};

interface IRefreshInventoryButtonProps extends ButtonProps {
  refreshNFTType: TNFTType;
  ownerId: string;
}

const RefreshInventoryButton = forwardRef((props: IRefreshInventoryButtonProps, ref) => {
  const { refreshNFTType, ownerId, ...remainingProps } = props;
  const { search } = useLocation();
  const { loading: isLoadingPathName } = PredefinedStateAndAction[refreshNFTType];
  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state: RootReducerState) => state.inventory[isLoadingPathName as keyof InventoryState]
  );
  const onClickRefresh = useCallback(() => {
    const queryParams = queryString.parse(search);
    const variables = coreUtils.standardizeVariablesForQuery(queryParams);
    // @ts-ignore
    dispatch(InventoryAction.getMyNFTs(refreshNFTType, variables, ownerId));
  }, [search, dispatch, refreshNFTType, ownerId]);

  return (
    <ButtonWithHover
      {...remainingProps}
      ref={ref}
      onClick={onClickRefresh}
      isLoading={isLoading as boolean}
      bg="transparent"
      _hover={{ backgroundColor: 'transparent' }}
      _active={{ backgroundColor: 'transparent' }}
    >
      <Image boxSize={AppSizes.MenuIcon} objectFit="cover" src={Resources.svgs.refresh} />
    </ButtonWithHover>
  );
});

export default RefreshInventoryButton;
