import React, { useEffect, memo } from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { MarketplaceAction, RootReducerState } from 'src/redux';
import { IFixedPriceSaleNFT, INFT, TNFTType } from 'src/gql/types';
import { EmptyView, Spinner } from 'src/components';
import { coreUtils } from 'src/common';
import isEqual from 'lodash/isEqual';
import SaleHistoryItem from './SaleHistoryItem';

interface OwnProps {
  nftType: TNFTType;
  tokenId: string;
  showTitle?: boolean;
  titleStyle?: TextProps;
}

interface StateProps {
  isGettingSaleHistories: boolean;
  listOfSaleHistories: Array<IFixedPriceSaleNFT>;
}

interface DispatchProps {
  onGetSaleHistories: (tokenId: string) => void;
}

type NFTDetailProps = OwnProps & StateProps & DispatchProps;

const SaleHistoryList = (props: NFTDetailProps) => {
  const { t } = useTranslation();
  const {
    nftType,
    isGettingSaleHistories,
    onGetSaleHistories,
    tokenId,
    showTitle = true,
    titleStyle = {},
    listOfSaleHistories,
  } = props;

  useEffect(() => {
    onGetSaleHistories(tokenId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenId]);

  const titleSectionStyle = { ...styles.titleSection, ...titleStyle };
  const emptyDescription = t('Component:SaleHistoryList.EmptyList');
  return (
    <>
      {showTitle && (
        <Text {...titleSectionStyle}>{t('Component:NFTDetail.Section.SaleHistories')}</Text>
      )}
      <Flex flex={{ base: 1 }} flexDirection={'column'} pos={'relative'}>
        {!isEmpty(listOfSaleHistories) && (
          <Flex direction={'row'} flexWrap={'wrap'} justifyContent={'center'}>
            {listOfSaleHistories.map((item: INFT | IFixedPriceSaleNFT) => (
              <SaleHistoryItem
                key={(item as IFixedPriceSaleNFT).id}
                item={item as IFixedPriceSaleNFT}
                rootPath={coreUtils.getPathByNFTType(nftType)}
              />
            ))}
          </Flex>
        )}
        {!isGettingSaleHistories && isEmpty(listOfSaleHistories) && (
          <EmptyView showImage={false} description={emptyDescription} />
        )}
        {isGettingSaleHistories && <Spinner size="sm" top={2} />}
      </Flex>
    </>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  const { marketplace } = state;
  const { isGettingSaleHistories, listOfSaleHistories } = marketplace;
  return {
    isGettingSaleHistories,
    listOfSaleHistories,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    onGetSaleHistories: (tokenId: string) =>
      dispatch<any>(MarketplaceAction.getSaleHistories(ownProps.nftType, tokenId)),
  };
};

const compareProps = (prevProps: NFTDetailProps, nextProps: NFTDetailProps) => {
  // if this function returns true, this component does not need to re-render
  const compared = !(
    !isEqual(prevProps.nftType, nextProps.nftType) ||
    !isEqual(prevProps.tokenId, nextProps.tokenId) ||
    !isEqual(prevProps.isGettingSaleHistories, nextProps.isGettingSaleHistories) ||
    !isEqual(prevProps.listOfSaleHistories, nextProps.listOfSaleHistories) ||
    !isEqual(prevProps.showTitle, nextProps.showTitle) ||
    !isEqual(prevProps.titleStyle, nextProps.titleStyle)
  );
  return compared;
};

export default connect<StateProps, DispatchProps, OwnProps, RootReducerState>(
  mapStateToProps,
  mapDispatchToProps
)(memo(SaleHistoryList, compareProps));

const styles = {
  titleSection: {
    fontSize: 'lg',
    pt: 4,
    pb: 2,
    fontWeight: 'bold' as 'bold',
  },
};
