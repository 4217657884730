import React, { useEffect } from 'react';
import { Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { DashboardAction, DashboardTypes, RootReducerState } from 'src/redux';
import { IFixedPriceSaleNFT, INFT, TNFTType } from 'src/gql/types';
import { EmptyView, LinkButton, Spinner } from 'src/components';
import { RecentlyListKey } from 'src/redux/dashboard/types';
import { coreUtils } from 'src/common';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Colors } from 'src/shared';
import * as common from '../common';
import { RoutePath } from '../../../globals/constants';
import RecentlyListItem from './RecentlyListItem';

interface OwnProps {
  nftType: TNFTType;
  recentlyListKey: DashboardTypes.TRecentlyListKey;
}

interface StateProps {
  isGettingFixedPriceSaleNFTs: boolean;
  listOfFixedPriceSaleNFTs: Array<IFixedPriceSaleNFT>;
}

interface DispatchProps {
  onGetRecentlyListDataByNFT: () => void;
}

type RecentlyListProps = OwnProps & StateProps & DispatchProps;

const RecentlyList = (props: RecentlyListProps) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);

  const {
    recentlyListKey,
    nftType,
    isGettingFixedPriceSaleNFTs,
    onGetRecentlyListDataByNFT,
    listOfFixedPriceSaleNFTs,
  } = props;

  useEffect(() => {
    onGetRecentlyListDataByNFT();
  }, [onGetRecentlyListDataByNFT]);
  const emptyDescription = t('Component:RecentlyList.EmptyList');
  return (
    <Flex direction="column" flex={1}>
      {recentlyListKey === RecentlyListKey.RECENTLY_LISTED && (
        <Flex justify="space-between" align="center" height={10}>
          <Heading as="h2" color="heading" fontSize="2xl">
            {t('Component:RecentlyList.Title.RecentlyListed')}
          </Heading>
          <LinkButton to={RoutePath.MINING_TOOL} color={textColor} paddingInline={0}>
            {t('Component:RecentlyList.ViewMore')}
            <ChevronRightIcon h={6} w={6} />
          </LinkButton>
        </Flex>
      )}
      {recentlyListKey === RecentlyListKey.RECENTLY_SOLD && (
        <Flex justify="space-between" align="center" w="full" height={10}>
          <Heading as="h2" color="heading" fontSize="2xl">
            {t('Component:RecentlyList.Title.RecentlySold')}
          </Heading>
        </Flex>
      )}
      <Flex flex={{ base: 1 }} minHeight="120px" flexDirection="column" pos="relative">
        {!isEmpty(listOfFixedPriceSaleNFTs) && (
          <Flex direction="row" flexWrap="wrap" justifyContent="center">
            {listOfFixedPriceSaleNFTs.map((item: INFT | IFixedPriceSaleNFT) => (
              <RecentlyListItem
                recentlyListKey={recentlyListKey}
                nftType={nftType}
                key={(item as IFixedPriceSaleNFT).id}
                item={item as IFixedPriceSaleNFT}
                rootPath={coreUtils.getPathByNFTType(nftType)}
              />
            ))}
          </Flex>
        )}
        {!isGettingFixedPriceSaleNFTs && isEmpty(listOfFixedPriceSaleNFTs) && (
          <EmptyView showImage={false} description={emptyDescription} />
        )}
        {isGettingFixedPriceSaleNFTs && <Spinner size="md" top={2} />}
      </Flex>
    </Flex>
  );
};

const mapStateToProps = (state: RootReducerState, ownProps: OwnProps) => {
  const { dashboard } = state;
  const { [ownProps.recentlyListKey]: list } = dashboard;
  const data = common.getRecentlyListDataByNFTType(list, ownProps.nftType);
  return {
    listOfFixedPriceSaleNFTs: data.listOfFixedPriceSaleNFTs,
    isGettingFixedPriceSaleNFTs: data.isGetting,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const action =
    ownProps.recentlyListKey === RecentlyListKey.RECENTLY_SOLD
      ? DashboardAction.getRecentlySoldByNFT
      : DashboardAction.getRecentlyListedByNFT;
  return {
    onGetRecentlyListDataByNFT: () => dispatch<any>(action(ownProps.nftType)),
  };
};
export default connect<StateProps, DispatchProps, OwnProps, RootReducerState>(
  mapStateToProps,
  mapDispatchToProps
)(RecentlyList);
