import { ParsedQuery } from 'query-string';
import has from 'lodash/has';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import {
  DefaultLimit,
  GeneClassType,
  GenePartType,
  NFTType,
  QueryString,
  RarityType,
  ToolCategoryType,
  FixedPriceSaleStatus,
  DefaultMiningToolMetadata,
  DefaultGeneMetadata,
  DefaultLandMetadata,
  RoutePath,
  ff32,
  ff24,
} from 'src/globals/constants';
import {
  IFilter,
  IFixedPriceSaleNFT,
  IMarketPrice,
  IOrderByKeyInput,
  IPaginationInput,
  IUserMeta,
  TNFTMetadata,
  TNFTType,
} from 'src/gql/types';
import {
  GENE_RESOURCE_URL,
  LAND_RESOURCE_URL,
  MINING_TOOL_RESOURCE_URL,
} from 'src/globals/configs';
import { Colors } from 'src/shared';
import { ContractType } from 'src/metamask-provider/type';
import BN from 'bn.js';
import { IRequestFilterBase } from '../globals/types';
import * as appUtils from './utils';
import { coreUtils } from './index';

export const standardizeURLPagination = (skip = 0, limit = DefaultLimit): { page: number } => {
  const pageNumber = skip / limit + 1;
  return { page: pageNumber };
};

export const standardizeAPIPagination = (
  pageNumber = 0,
  limit = DefaultLimit
): IPaginationInput => {
  const skip = (pageNumber - 1) * limit;
  return { skip, limit };
};

export const standardizeAPIOrderByInput = ({
  key,
  order,
}: {
  key: string;
  order: 'asc' | 'desc';
}): IOrderByKeyInput => {
  return { [key]: order };
};

// Function use to standardize value when getting info from BE to show on web
export const standardizeDataToShowed = (value?: number) => {
  if (value) return value / 100; // because data on contract not use float number, so must be / 100 before to show
  return 0;
};

export const padTemplateId = (templateId: BN | number) => {
  return templateId.toString(16).padStart(8, '0');
};

// Function use to standardize value when querying NFTData
export const standardizeValueToNFTData = (value?: number) => {
  if (value) return value * 100;
  return 0;
};

export const standardizeMultipleSelectionData = (
  data: string | string[],
  convertData = appUtils.convertStringToNumber
) => {
  if (isEmpty(data)) {
    return undefined;
  }
  if (!Array.isArray(data)) {
    data = [data];
  }
  return orderBy(map(data, (e) => convertData(e)));
};

export const standardizeRangeSelectionData = (
  data: string | string[],
  convertData = appUtils.convertStringToNumber
) => {
  if (isEmpty(data) || !Array.isArray(data)) {
    return undefined;
  }
  return orderBy(map(data, (e) => convertData(e)));
};

// Function use to get filter Data because standardizeVariablesForQuery may change NFTData to query
export const standardizeFilterToQuery = (
  queryParams: ParsedQuery,
  filterBase?: IRequestFilterBase
): IFilter => {
  const filter: IFilter = {};
  if (has(filterBase, QueryString.SEARCH_QUERY) && has(queryParams, QueryString.SEARCH_QUERY)) {
    const searchString = get(queryParams, QueryString.SEARCH_QUERY, '');
    if (!isEmpty(searchString)) {
      filter.search = searchString as string;
    }
  }
  if (has(filterBase, QueryString.CATEGORY) && has(queryParams, QueryString.CATEGORY)) {
    filter.cat = standardizeMultipleSelectionData(queryParams?.[QueryString.CATEGORY] ?? []);
  }
  if (has(filterBase, QueryString.RARITY) && has(queryParams, QueryString.RARITY)) {
    filter.rarity = standardizeMultipleSelectionData(queryParams?.[QueryString.RARITY] ?? []);
  }
  if (has(filterBase, QueryString.MINING_POWER) && has(queryParams, QueryString.MINING_POWER)) {
    filter.mp = standardizeRangeSelectionData(queryParams?.[QueryString.MINING_POWER] ?? []);
  }
  if (has(filterBase, QueryString.LUCK) && has(queryParams, QueryString.LUCK)) {
    filter.luck = standardizeRangeSelectionData(queryParams?.[QueryString.LUCK] ?? []);
  }
  if (has(filterBase, QueryString.COOLDOWN) && has(queryParams, QueryString.COOLDOWN)) {
    filter.cd = standardizeRangeSelectionData(queryParams?.[QueryString.COOLDOWN] ?? []);
  }
  if (has(filterBase, QueryString.PART) && has(queryParams, QueryString.PART)) {
    filter.part = standardizeMultipleSelectionData(queryParams?.[QueryString.PART] ?? []);
  }
  if (has(filterBase, QueryString.GENE_CLASS) && has(queryParams, QueryString.GENE_CLASS)) {
    filter.geneClass = standardizeMultipleSelectionData(
      queryParams?.[QueryString.GENE_CLASS] ?? []
    );
  }
  return filter;
};

export const standardizeVariablesForQuery = (queryParams: ParsedQuery) => {
  const sortType = get(queryParams, QueryString.SORT, '');
  const pageNumber = Number(get(queryParams, QueryString.PAGE, 1));
  const sortByType = appUtils.getSortByType(sortType as string);
  const orderBy = sortByType && standardizeAPIOrderByInput(sortByType);
  let filter: IFilter = {};
  if (has(queryParams, QueryString.SEARCH_QUERY)) {
    const searchString = get(queryParams, QueryString.SEARCH_QUERY, '');
    if (!isEmpty(searchString)) {
      filter.search = searchString as string;
    }
  }
  if (has(queryParams, QueryString.CATEGORY)) {
    filter.cat = standardizeMultipleSelectionData(queryParams?.[QueryString.CATEGORY] ?? []);
  }
  if (has(queryParams, QueryString.RARITY)) {
    filter.rarity = standardizeMultipleSelectionData(queryParams?.[QueryString.RARITY] ?? []);
  }
  if (has(queryParams, QueryString.MINING_POWER)) {
    filter.mp = standardizeRangeSelectionData(
      queryParams?.[QueryString.MINING_POWER] ?? [],
      (i) => {
        const value = appUtils.convertStringToNumber(i);
        return standardizeValueToNFTData(value);
      }
    );
  }
  if (has(queryParams, QueryString.LUCK)) {
    filter.luck = standardizeRangeSelectionData(queryParams?.[QueryString.LUCK] ?? [], (i) => {
      const value = appUtils.convertStringToNumber(i);
      return standardizeValueToNFTData(value);
    });
  }
  if (has(queryParams, QueryString.COOLDOWN)) {
    filter.cd = standardizeRangeSelectionData(queryParams?.[QueryString.COOLDOWN] ?? [], (i) => {
      const value = appUtils.convertStringToNumber(i);
      return standardizeValueToNFTData(value);
    });
  }
  if (has(queryParams, QueryString.PART)) {
    filter.part = standardizeMultipleSelectionData(queryParams?.[QueryString.PART] ?? []);
  }
  if (has(queryParams, QueryString.GENE_CLASS)) {
    filter.geneClass = standardizeMultipleSelectionData(
      queryParams?.[QueryString.GENE_CLASS] ?? []
    );
  }
  return {
    pagination: standardizeAPIPagination(pageNumber),
    orderBy,
    filter,
  };
};

export const getTemplateId = (tokenId: BN) => {
  return tokenId.ushrn(64).and(ff32);
};

export const getOriginalTemplateId = (tokenId: BN) => {
  return tokenId.ushrn(64).and(ff24);
};

export const getImageByTemplateId = (templateId: BN, nftType: TNFTType) => {
  const mappingTemplateId = templateId.toString(16).toLowerCase();

  switch (nftType) {
    case NFTType.MINING_TOOL:
      return `${MINING_TOOL_RESOURCE_URL}/tool_${mappingTemplateId}.png`;
    case NFTType.GENE:
      return `${GENE_RESOURCE_URL}/gene_${mappingTemplateId}.png`;
    case NFTType.LAND:
      return `${LAND_RESOURCE_URL}/land_${mappingTemplateId}.png`;
    case NFTType.GACHA:
      return `${MINING_TOOL_RESOURCE_URL}/demo-tool.png`;
    case NFTType.TOOL_MATERIAL:
      return `${MINING_TOOL_RESOURCE_URL}/demo-tool.png`;
    default:
      throw new Error(`Please implement getImageByTemplateId with type ${nftType}`);
  }
};

export const getImageByTokenId = (tokenId: BN, nftType: TNFTType) => {
  const mappingTokenId = tokenId.toString(16).toLowerCase();

  switch (nftType) {
    case NFTType.LAND:
      return `${LAND_RESOURCE_URL}/land_${mappingTokenId}.png`;
    default:
      throw new Error(`Please implement getImageByTokenId with type ${nftType}`);
  }
};
export const getAttributesByCatId = (
  rarity: number
): { label: string; light: string; dark: string } | null => {
  switch (rarity) {
    case ToolCategoryType.HYBRID: {
      return {
        label: 'Term:Hybrid',
        light: Colors.light.catHybrid,
        dark: Colors.dark.catHybrid,
      };
    }
    case ToolCategoryType.LUCK: {
      return {
        label: 'Term:Luck',
        light: Colors.light.catLuck,
        dark: Colors.dark.catLuck,
      };
    }
    case ToolCategoryType.MINING_POWER:
      return {
        label: 'Term:MiningPower',
        light: Colors.light.catMiningPower,
        dark: Colors.dark.catMiningPower,
      };
    default:
  }
  return null;
};

export const getAttributesByRarity = (
  rarity: number
): { label: string; light: string; dark: string } | null => {
  switch (rarity) {
    case RarityType.LEGENDARY: {
      return {
        label: 'Term:Rarity.Legendary',
        light: Colors.light.rarityLegendary,
        dark: Colors.dark.rarityLegendary,
      };
    }
    case RarityType.EPIC: {
      return {
        label: 'Term:Rarity.Epic',
        light: Colors.light.rarityEpic,
        dark: Colors.dark.rarityEpic,
      };
    }
    case RarityType.RARE: {
      return {
        label: 'Term:Rarity.Rare',
        light: Colors.light.rarityRare,
        dark: Colors.dark.rarityRare,
      };
    }
    case RarityType.UNCOMMON: {
      return {
        label: 'Term:Rarity.Uncommon',
        light: Colors.light.rarityUncommon,
        dark: Colors.dark.rarityUncommon,
      };
    }
    case RarityType.COMMON:
      return {
        label: 'Term:Rarity.Common',
        light: Colors.light.rarityCommon,
        dark: Colors.dark.rarityCommon,
      };
    default:
  }
  return null;
};

export const getAttributesByGeneClass = (
  geneClass: number
): { label: string; light: string; dark: string } | null => {
  switch (geneClass) {
    case GeneClassType.GENERIC: {
      return {
        label: 'Term:GeneClass.Generic',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GeneClassType.CRUSADER: {
      return {
        label: 'Term:GeneClass.Crusader',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GeneClassType.WIZARD: {
      return {
        label: 'Term:GeneClass.Wizard',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GeneClassType.ASSASSIN: {
      return {
        label: 'Term:GeneClass.Assassin',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GeneClassType.SAINT:
      return {
        label: 'Term:GeneClass.Saint',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    case GeneClassType.SHAMAN:
      return {
        label: 'Term:GeneClass.Shaman',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    default:
  }
  return null;
};

export const getAttributesByPart = (
  part: number
): { label: string; light: string; dark: string } | null => {
  switch (part) {
    case GenePartType.BODY: {
      return {
        label: 'Term:Part.Body',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GenePartType.HEAD: {
      return {
        label: 'Term:Part.Head',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GenePartType.LEFT_ARM: {
      return {
        label: 'Term:Part.LeftArm',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GenePartType.RIGHT_ARM: {
      return {
        label: 'Term:Part.RightArm',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GenePartType.SUB:
      return {
        label: 'Term:Part.Sub',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    default:
  }
  return null;
};

export const getNFTContractNameByNftType = (nftType: TNFTType): ContractType => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return 'FantasyToolNFTContract';
    case NFTType.GENE:
      return 'FantasyGeneNFTContract';
    case NFTType.LAND:
      return 'FantasyLandNFTContract';
    case NFTType.GACHA:
      return 'FantasyGachaBoxNFTContract';
    case NFTType.TOOL_MATERIAL:
      return 'FantasyToolMaterialNFTContract';
    default:
      throw new Error(`getMarketContractNameByNftType Unsupported NFT type ${nftType}`);
  }
};

export const getPriceInfoByStatus = (fixedPriceSaleNFT: IFixedPriceSaleNFT): IMarketPrice => {
  const hasSold = fixedPriceSaleNFT?.status === FixedPriceSaleStatus.BOUGHT;
  return hasSold ? fixedPriceSaleNFT?.buyPrice : fixedPriceSaleNFT?.sellPrice;
};

export const getOwnerInfoByStatus = (fixedPriceSaleNFT: IFixedPriceSaleNFT): IUserMeta => {
  const hasSold = fixedPriceSaleNFT?.status === FixedPriceSaleStatus.BOUGHT;
  return hasSold ? fixedPriceSaleNFT?.buyer : fixedPriceSaleNFT?.seller;
};

export const getDefaultMetadataByNftType = (nftType: TNFTType): TNFTMetadata => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return DefaultMiningToolMetadata;
    case NFTType.GENE:
      return DefaultGeneMetadata;
    case NFTType.LAND:
      return DefaultLandMetadata;
    case NFTType.GACHA:
    case NFTType.TOOL_MATERIAL:
      return null;
    default:
      throw new Error(`Please implement getDefaultMetadataByNftType with type ${nftType}`);
  }
};

export const getNFTTokenNameByNftType = (nftType?: TNFTType): string => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return 'Term:Marketplace.MiningToolNFTToken';
    case NFTType.GENE:
      return 'Term:Marketplace.GeneNFTToken';
    case NFTType.LAND:
      return 'Term:Marketplace.LandNFTToken';
    case NFTType.GACHA:
      return 'Term:Marketplace.GachaNFTToken';
    case NFTType.TOOL_MATERIAL:
      return 'Term:Marketplace.ToolMaterialNFTToken';
    default:
      return 'Term:Unknown';
  }
};

export const getNFTTypeNameByNftType = (nftType?: TNFTType): string => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return 'Term:MiningTool';
    case NFTType.GENE:
      return 'Term:Gene';
    case NFTType.LAND:
      return 'Term:Land';
    case NFTType.GACHA:
      return 'Term:GachaBox';
    case NFTType.TOOL_MATERIAL:
      return 'Term:ToolMaterial';
    default:
      return 'Term:Unknown';
  }
};

export const getPathByNFTType = (nftType: TNFTType): string => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return RoutePath.MINING_TOOL;
    case NFTType.GENE:
      return RoutePath.GENE;
    case NFTType.LAND:
      return RoutePath.LAND;
    case NFTType.GACHA:
      return RoutePath.GACHA_BOX;
    case NFTType.TOOL_MATERIAL:
      return RoutePath.TOOL_MATERIAL;
    default:
      return RoutePath.ROOT;
  }
};
