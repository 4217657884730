import { gql } from '@apollo/client';

export const GetNFTSummaryQuery = gql`
  query GetNFTSummary {
    getNFTSummary {
      miningTool {
        category {
          dataType
          values
        }
        rarity {
          dataType
          values
        }
        mp {
          max
          min
          unit
        }
        cd {
          min
          max
          unit
        }
        luck {
          max
          min
          unit
        }
      }
      land {
        mp {
          min
          max
          unit
        }
        cd {
          max
          min
          unit
        }
        luck {
          min
          max
          unit
        }
      }
      gene {
        geneClass {
          dataType
          values
        }
        part {
          dataType
          values
        }
        rarity {
          values
          dataType
        }
        supply {
          min
          max
          unit
        }
        weight {
          unit
          max
          min
        }
      }
    }
  }
`;
