// Update this list when using new env variables
export type ENV_VARIABLES =
  | 'REACT_APP_ENV'
  | 'REACT_APP_SENTRY_DSN'
  | 'REACT_APP_FANTASY_TOKEN_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_TOOL_NFT_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_MINING_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_LAND_NFT_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_GENE_NFT_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_GACHA_BOX_NFT_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_TOOL_MATERIAL_NFT_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_TOOL_MARKET_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_LAND_MARKET_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_GENE_MARKET_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_GACHA_BOX_MARKET_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_TOOL_MATERIAL_MARKET_CONTRACT_ADDRESS'
  | 'REACT_APP_FANTASY_GRAPHQL_API'
  | 'REACT_APP_FANTASY_RESOURCE_URL'
  | 'REACT_APP_TRANSACTION_EXPLORER_URL'
  | 'REACT_APP_ADDRESS_EXPLORER_URL'
  | 'REACT_APP_MAX_PRICE_SUPPORTED'
  | 'REACT_APP_MIN_PRICE_SUPPORTED'
  | 'REACT_APP_IS_SHOW_DASHBOARD_STATISTICS'
  | 'REACT_APP_IS_SHOW_SALE_HISTORIES'
  | 'REACT_APP_GA_TRACKING_CODE';

export const getConfig = (name: ENV_VARIABLES): string => {
  const value = process.env[name];
  if (!value) {
    throw new Error(`Configuration ${name} is invalid`);
  }
  return value;
};
