import { useCallback, useState } from 'react';
import MetamaskProvider from 'src/metamask-provider';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';

const useTransferNFTAsGift = (nftType: TNFTType) => {
  const [error, setError] = useState<string | undefined>();
  const [isTransferring, setIsTransferring] = useState<boolean | undefined>();
  const onTransferNFT = useCallback(
    (toAddress: string, tokenId: string, amount: string, onGiftDone?: (txid: string) => void) => {
      setError(undefined);
      setIsTransferring(true);
      MetamaskProvider.transferNFT(
        coreUtils.getNFTContractNameByNftType(nftType),
        toAddress,
        tokenId,
        amount
      )
        .then((receipt) => {
          if (onGiftDone) {
            onGiftDone(receipt.transactionHash);
          }
        })
        .catch((error) => {
          setError(error.message);
          console.error(error);
        })
        .finally(() => {
          setIsTransferring(false);
        });
    },
    [nftType, setIsTransferring]
  );

  return [isTransferring, onTransferNFT, error];
};

export default useTransferNFTAsGift;
