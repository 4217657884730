import { gql } from '@apollo/client';

export const GENE_METADATA_KEY = 'geneMetadata';
export const getGeneMetadataFragment = (key = GENE_METADATA_KEY) => gql`
  fragment ${key} on GeneMetadata {
    templateId
    name
    geneClass
    part
    rarity
    supply
    weight
  }
`;
