const colors = {
  dark: {
    bg: '#071121',
    secondaryBg: '#06162F',
    cardBg: '#0F213E',
    divider: '#22314A',
    primary: '#FF592B',
    secondary: '#046CFC',
    text: '#FFFFFF',
    whiteText: '#FFFFFF',
    price: '#44B26B',
    description: '#808191',
    tokenTool: '#FF805D',
    nftTool: '#FF805D',
    selectionBg: '#2e2e2e',
    rarityCommon: '#ACACAC',
    rarityUncommon: '#00AB37',
    rarityRare: '#1F72EF',
    rarityEpic: '#6614ED',
    rarityLegendary: '#FFAA2B',
    catMiningPower: '#ACACAC',
    catLuck: '#00AB37',
    catHybrid: '#6614ED',
    iconLabelBg: '#22314A',
    modalBackground: '#0D1D39',
    addressBackground: '#0D1D39',
    label: '#22314A',
    statTotal: '#416EE2',
    statVolume: '#FDAB0F',
    statAverage: '#48BB78',
    statTransaction: '#805AD5',
    quantity: '#FFAA2B',
    success: '#44B26B',
  },

  light: {
    bg: '#e3e3e3',
    secondaryBg: '#FFFFFF',
    cardBg: '#e3e3e3',
    divider: '#e3e3e3',
    primary: '#FF592B',
    secondary: '#046CFC',
    text: '#071121',
    whiteText: '#FFFFFF',
    price: '#44B26B',
    description: '#808191',
    tokenTool: '#FF805D',
    nftTool: '#FF805D',
    selectionBg: '#b6b6b6',
    rarityCommon: '#ACACAC',
    rarityUncommon: '#00AB37',
    rarityRare: '#1F72EF',
    rarityEpic: '#6614ED',
    rarityLegendary: '#FFAA2B',
    catMiningPower: '#ACACAC',
    catLuck: '#00AB37',
    catHybrid: '#6614ED',
    iconLabelBg: '#e3e3e3',
    modalBackground: '#e3e3e3',
    addressBackground: '#FFFFFF',
    label: '#e3e3e3',
    statTotal: '#416EE2',
    statVolume: '#FDAB0F',
    statAverage: '#48BB78',
    statTransaction: '#805AD5',
    quantity: '#FFAA2B',
    success: '#44B26B',
  },
};

export default colors;
