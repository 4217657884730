import { IRequestFilterBase } from 'src/globals/types';
import { SupportedLanguage, SupportedTheme } from '../../globals/constants';

export const TOGGLE_MOBILE_NAVBAR = 'TOGGLE_MOBILE_NAVBAR';
export const TOGGLE_INVENTORY_FILTER = 'TOGGLE_INVENTORY_FILTER';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const OPEN_ERROR_MODAL = 'OPEN_ERROR_MODAL';
export const CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL';
export const UPDATE_FILTER_CONFIGS = 'UPDATE_FILTER_CONFIGS';
export const SET_APP_LANGUAGE = 'SET_APP_LANGUAGE';
export const SET_APP_THEME = 'SET_APP_THEME';

export interface AppState {
  mobileNavbarVisible: boolean;
  inventoryFilterVisible: boolean;
  sidebarVisible: boolean;
  isOpenErrorModal: boolean;
  errorModalMessage?: string;
  filterConfigs: IFilterConfigs;
  selectedLang: SupportedLanguage;
  selectedTheme: SupportedTheme;
}

export interface IFilterConfigs {
  miningTool?: IRequestFilterBase;
  land?: IRequestFilterBase;
  gene?: IRequestFilterBase;
}
