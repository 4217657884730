import React from 'react';
import {
  DashboardIcon,
  InventoryIcon,
  MarketplaceIcon,
  UserActivityIcon,
} from 'src/shared/svgComponents';
import { AppSizes } from 'src/shared';
import { MenuLabel } from 'src/globals/constants';

interface MenuIconProps {
  label?: string;
  color?: string;
}
const MenuIcon = (props: MenuIconProps) => {
  const { label, color } = props;
  switch (label) {
    case MenuLabel.DASHBOARD:
      return <DashboardIcon width={AppSizes.MenuIcon} height={AppSizes.MenuIcon} color={color} />;
    case MenuLabel.MARKETPLACE:
      return <MarketplaceIcon width={AppSizes.MenuIcon} height={AppSizes.MenuIcon} color={color} />;
    case MenuLabel.INVENTORY:
      return <InventoryIcon width={AppSizes.MenuIcon} height={AppSizes.MenuIcon} color={color} />;
    case MenuLabel.ACTIVITY:
      return (
        <UserActivityIcon width={AppSizes.MenuIcon} height={AppSizes.MenuIcon} color={color} />
      );
  }
  return null;
};
export default MenuIcon;
