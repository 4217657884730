import { NFTType } from '../globals/constants';
import { IFilter, INFTFilterByType, INFTOrderByType, IOrderByKeyInput, TNFTType } from './types';

export const getGQLNFTDataType = (nftType: TNFTType) => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return 'MiningTool';
    case NFTType.GENE:
      return 'Gene';
    case NFTType.LAND:
      return 'Land';
    case NFTType.GACHA:
      return 'GachaBox';
    case NFTType.TOOL_MATERIAL:
      return 'ToolMaterial';
    default:
      throw new Error(`Please implement getGQLNFTDataType with type ${nftType}`);
  }
};

export const getFilterVariableByType = (
  nftType: TNFTType,
  filter: IFilter
): INFTFilterByType | undefined => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return { tool: filter };
    case NFTType.GENE:
      return { gene: filter };
    case NFTType.LAND:
      return { land: filter };
    case NFTType.GACHA:
    case NFTType.TOOL_MATERIAL:
      return undefined;
    default:
      throw new Error(`Please implement getFilterVariableByType with type ${nftType}`);
  }
};

export const getOrderByVariableByType = (
  nftType: TNFTType,
  orderByKeyInput: IOrderByKeyInput
): INFTOrderByType => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return { tool: orderByKeyInput };
    case NFTType.GENE:
      return { gene: orderByKeyInput };
    case NFTType.LAND:
      return { land: orderByKeyInput };
    case NFTType.GACHA:
      return { gacha: orderByKeyInput };
    case NFTType.TOOL_MATERIAL:
      return { toolMaterial: orderByKeyInput };
    default:
      throw new Error(`Please implement getOrderByVariableByType with type ${nftType}`);
  }
};
