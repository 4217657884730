import { Box, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  DefaultLimit,
  GeneSaleSortTypes,
  LandSaleSortTypes,
  MiningToolSaleSortTypes,
  NFTType,
  StandardSaleSortTypes,
} from '../globals/constants';
import { RootReducerState } from '../redux';
import Colors from '../shared/foundations/colors';
import { SortingSelector } from '../features/sorting';
import { Pagination } from './Pagination';

interface MarketHeaderProps {
  nftType: NFTType;
}

const MarketHeader = (props: MarketHeaderProps) => {
  const { nftType } = props;
  const { t } = useTranslation();
  const [totalOfNfts, setTotalOfNfts] = React.useState(0);
  const [totalOfPages, setTotalOfPages] = React.useState(0);
  const [title, setTitle] = React.useState('');
  const [sortType, setSortType] = React.useState(StandardSaleSortTypes);

  const defaultBg = useColorModeValue(Colors.light.secondaryBg, Colors.dark.secondaryBg);

  const totalOfMiningTools = useSelector(
    (state: RootReducerState) => state.marketplace.totalOfMiningTools
  );
  const totalOfToolMaterials = useSelector(
    (state: RootReducerState) => state.marketplace.totalOfToolMaterials
  );
  const totalOfLands = useSelector((state: RootReducerState) => state.marketplace.totalOfLands);
  const totalOfGachaBoxes = useSelector(
    (state: RootReducerState) => state.marketplace.totalOfGachaBoxes
  );
  const totalOfGenes = useSelector((state: RootReducerState) => state.marketplace.totalOfGenes);

  useEffect(() => {
    switch (nftType) {
      case NFTType.MINING_TOOL:
        setTotalOfNfts(totalOfMiningTools);
        setTotalOfPages(Math.ceil(totalOfMiningTools / DefaultLimit));
        setTitle(t('Term:MiningTool'));
        setSortType(MiningToolSaleSortTypes);
        break;
      case NFTType.TOOL_MATERIAL:
        setTotalOfNfts(totalOfToolMaterials);
        setTotalOfPages(Math.ceil(totalOfToolMaterials / DefaultLimit));
        setTitle(t('Term:ToolMaterial'));
        setSortType(StandardSaleSortTypes);
        break;
      case NFTType.LAND:
        setTotalOfNfts(totalOfLands);
        setTotalOfPages(Math.ceil(totalOfLands / DefaultLimit));
        setTitle(t('Term:Land'));
        setSortType(LandSaleSortTypes);
        break;
      case NFTType.GACHA:
        setTotalOfNfts(totalOfGachaBoxes);
        setTotalOfPages(Math.ceil(totalOfGachaBoxes / DefaultLimit));
        setTitle(t('Term:Gacha'));
        setSortType(StandardSaleSortTypes);
        break;
      case NFTType.GENE:
        setTotalOfNfts(totalOfGenes);
        setTotalOfPages(Math.ceil(totalOfGenes / DefaultLimit));
        setTitle(t('Term:Gene'));
        setSortType(GeneSaleSortTypes);
        break;
    }
  }, [
    nftType,
    totalOfMiningTools,
    totalOfToolMaterials,
    totalOfLands,
    totalOfGachaBoxes,
    totalOfGenes,
    t,
  ]);

  return (
    <Flex
      direction={{ base: 'column', lg: 'row' }}
      justifyContent={{ base: 'center', md: 'space-between' }}
      alignItems="center"
      position="sticky"
      zIndex={8}
      background={defaultBg}
      padding={{ base: 5, lg: 0 }}
      top={{ base: 140, lg: 126.5 }}
    >
      <Box as="span">
        <Heading as="h1" color="heading" flex={1} order={{ base: 1, md: 0 }}>
          {t(title)} ({totalOfNfts.toLocaleString('en')})
        </Heading>
      </Box>
      <Pagination pageTotal={totalOfPages} />
      <Flex flexWrap="wrap" order={{ base: 0, md: 1 }}>
        <SortingSelector marginRight={2} marginBottom={2} options={sortType} />
      </Flex>
    </Flex>
  );
};

export default MarketHeader;
