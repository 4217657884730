import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { MarketplaceAction, RootReducerState } from 'src/redux';
import { coreUtils } from 'src/common';
import { IAppVariable, IFixedPriceSaleNFT, TNFTType } from 'src/gql/types';
import { NFTType } from 'src/globals/constants';
import { SEO } from 'src/seo';
import { getEmptyMessageByNFTType } from '../common';
import Listing from './Listing';
import FixedPriceSaleNFTItem from './FixedPriceSaleNFTItem';

interface OwnProps {
  ownerId?: string;
  nftType: TNFTType;
}

interface StateProps {
  isGettingNFTs: boolean;
  listOfNFTs: Array<IFixedPriceSaleNFT>;
  totalOfNFTs: number;
}

interface DispatchProps {
  onGetNFTs: (variables: IAppVariable) => void;
}

type NFTListProps = OwnProps & StateProps & DispatchProps;

const FixedPriceSaleNFTList = (props: NFTListProps) => {
  const { isGettingNFTs, listOfNFTs, onGetNFTs, totalOfNFTs, ownerId, nftType } = props;
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  useEffect(() => {
    const queryParams = queryString.parse(search);
    const variables = coreUtils.standardizeVariablesForQuery(queryParams);
    onGetNFTs(variables);
  }, [search, ownerId, history, onGetNFTs]);
  return (
    <>
      <SEO title={t(coreUtils.getNFTTypeNameByNftType(nftType))} />
      <Listing
        emptyDescription={t(getEmptyMessageByNFTType(nftType))}
        loading={isGettingNFTs}
        data={listOfNFTs}
        total={totalOfNFTs}
        renderItem={({ item }) => (
          <FixedPriceSaleNFTItem
            key={(item as IFixedPriceSaleNFT).id}
            item={item as IFixedPriceSaleNFT}
            rootPath={coreUtils.getPathByNFTType(nftType)}
          />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: RootReducerState, ownProps: OwnProps) => {
  let listOfNFTs: IFixedPriceSaleNFT[];
  let isGettingNFTs: boolean;
  let totalOfNFTs: number;

  const { marketplace } = state;
  const {
    listOfMiningTools,
    totalOfMiningTools,
    isGettingMiningTools,
    listOfLands,
    totalOfLands,
    isGettingLands,
    listOfGenes,
    totalOfGenes,
    isGettingGenes,
    listOfGachaBoxes,
    totalOfGachaBoxes,
    isGettingGachaBoxes,
    listOfToolMaterials,
    isGettingToolMaterials,
    totalOfToolMaterials,
  } = marketplace;

  switch (ownProps.nftType) {
    case NFTType.LAND: {
      listOfNFTs = listOfLands;
      isGettingNFTs = isGettingLands;
      totalOfNFTs = totalOfLands;
      break;
    }
    case NFTType.GENE: {
      listOfNFTs = listOfGenes;
      isGettingNFTs = isGettingGenes;
      totalOfNFTs = totalOfGenes;
      break;
    }
    case NFTType.MINING_TOOL: {
      listOfNFTs = listOfMiningTools;
      isGettingNFTs = isGettingMiningTools;
      totalOfNFTs = totalOfMiningTools;
      break;
    }
    case NFTType.GACHA: {
      listOfNFTs = listOfGachaBoxes;
      isGettingNFTs = isGettingGachaBoxes;
      totalOfNFTs = totalOfGachaBoxes;
      break;
    }
    case NFTType.TOOL_MATERIAL: {
      listOfNFTs = listOfToolMaterials;
      isGettingNFTs = isGettingToolMaterials;
      totalOfNFTs = totalOfToolMaterials;
      break;
    }
    default: {
      listOfNFTs = [];
      isGettingNFTs = false;
      totalOfNFTs = 0;
      break;
    }
  }
  return {
    listOfNFTs,
    isGettingNFTs,
    totalOfNFTs,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    onGetNFTs: (variables: IAppVariable) =>
      dispatch<any>(
        MarketplaceAction.getAvailableFixedPriceSaleNFTsForSale(ownProps.nftType, variables)
      ),
  };
};

export default connect<StateProps, DispatchProps, OwnProps, RootReducerState>(
  mapStateToProps,
  mapDispatchToProps
)(FixedPriceSaleNFTList);
