import React from 'react';
import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IGeneMetadata } from 'src/gql/types';
import { RarityLabel, GenePartLabel, GeneClassLabel } from 'src/components';
import InfoItem from './InfoItem';

interface GeneAboutsProps {
  metadata: IGeneMetadata;
}

const GeneAbouts = (props: GeneAboutsProps) => {
  const { t } = useTranslation();

  const { metadata } = props;
  return (
    <HStack alignItems={'inherit'}>
      <InfoItem title={t('Term:GeneClass')}>
        <GeneClassLabel geneClass={metadata.geneClass} />
      </InfoItem>
      <InfoItem title={t('Term:Part')}>
        <GenePartLabel genePart={metadata.part} />
      </InfoItem>
      <InfoItem title={t('Term:Rarity')}>
        <RarityLabel rarity={metadata.rarity} />
      </InfoItem>
    </HStack>
  );
};

export default React.memo(GeneAbouts);
