import reduce from 'lodash/reduce';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import has from 'lodash/has';
import map from 'lodash/map';
import {
  IEnumFieldSummary,
  ILandSummary,
  IMiningToolSummary,
  INFTSummary,
  INumberFieldSummary,
} from 'src/gql/config/types';
import {
  INumberFilterAttribute,
  IRangeFilterAttribute,
  IRequestFilterBase,
} from 'src/globals/types';
import { coreUtils } from 'src/common';
import { IFilterConfigs } from './types';

export const standardizeFilterConfigs = (data: INFTSummary): IFilterConfigs => {
  return {
    miningTool: standardizeFilterBaseBySummary(data.miningTool),
    land: standardizeFilterBaseBySummary(data.land),
    gene: standardizeFilterBaseBySummary(data.gene),
  };
};

// rarity?: IEnumFieldSummary;
// category?: IEnumFieldSummary;
// mp?: INumberFieldSummary;
// cd?: INumberFieldSummary;
// luck?: INumberFieldSummary;

// cat?: INumberFilterAttribute;
// rarity?: INumberFilterAttribute;
// mp?: IRangeFilterAttribute;
// cd?: IRangeFilterAttribute;
// luck?: IRangeFilterAttribute;

const standardizeOptionFilterAttribute = (value: IEnumFieldSummary): INumberFilterAttribute => {
  return {
    value: map(value.values, (type: string) => Number(type)),
  };
};
const standardizeRangeFilterAttribute = (value: INumberFieldSummary): IRangeFilterAttribute => {
  const { min, max, unit } = value;
  const minValue = coreUtils.standardizeDataToShowed(min);
  const maxValue = coreUtils.standardizeDataToShowed(max);
  return {
    value: [minValue, maxValue],
    step: (maxValue - minValue) / 5,
    min: minValue,
    max: maxValue,
    minStepsBetweenThumbs: 1,
    unit,
  };
};

const standardizeFilterAttribute = (value: IEnumFieldSummary | INumberFieldSummary) => {
  if (has(value, 'min')) {
    return standardizeRangeFilterAttribute(value as INumberFieldSummary);
  } else if (has(value, 'dataType')) {
    return standardizeOptionFilterAttribute(value as IEnumFieldSummary);
  }
  return null;
};

const standardizeFilterBaseAndMapping = (
  key: string,
  value: IEnumFieldSummary | INumberFieldSummary
) => {
  switch (key) {
    case 'part':
      return { part: standardizeFilterAttribute(value) };
    case 'geneClass':
      return { geneClass: standardizeFilterAttribute(value) };
    case 'rarity':
      return { rarity: standardizeFilterAttribute(value) };
    case 'category':
      return { cat: standardizeFilterAttribute(value) };
    case 'mp':
      return { mp: standardizeFilterAttribute(value) };
    case 'cd':
      return { cd: standardizeFilterAttribute(value) };
    case 'luck':
      return { luck: standardizeFilterAttribute(value) };
  }
};

export const standardizeFilterBaseBySummary = (
  data: IMiningToolSummary | ILandSummary
): IRequestFilterBase => {
  const requestFilterBase = reduce(
    data,
    (result, value, key) => {
      return {
        ...result,
        ...standardizeFilterBaseAndMapping(key, value as IEnumFieldSummary | INumberFieldSummary),
      };
    },
    {}
  );

  return omitBy(requestFilterBase, isNil) as IRequestFilterBase;
};
