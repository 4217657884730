import get from 'lodash/get';
import { IAction } from '../types';
import * as Types from './types';

const initialState: Types.MarketplaceState = {
  isGettingMiningTools: false,
  gettingMiningToolsSuccess: false,
  gettingMiningToolsMessage: '',
  listOfMiningTools: [],
  totalOfMiningTools: 0,

  isGettingLands: false,
  gettingLandsSuccess: false,
  gettingLandsMessage: '',
  listOfLands: [],
  totalOfLands: 0,

  isGettingGenes: false,
  gettingGenesSuccess: false,
  gettingGenesMessage: '',
  listOfGenes: [],
  totalOfGenes: 0,

  isGettingGachaBoxes: false,
  gettingGachaBoxesSuccess: false,
  gettingGachaBoxesMessage: '',
  listOfGachaBoxes: [],
  totalOfGachaBoxes: 0,

  isGettingToolMaterials: false,
  gettingToolMaterialsSuccess: false,
  gettingToolMaterialsMessage: '',
  listOfToolMaterials: [],
  totalOfToolMaterials: 0,

  currentNFT: null,
  isGettingCurrentNFT: false,
  gettingCurrentNFTSuccess: false,
  gettingCurrentNFTMessage: '',

  isGettingSaleHistories: false,
  gettingSaleHistoriesSuccess: false,
  gettingSaleHistoriesMessage: '',
  listOfSaleHistories: [],
  totalSaleHistories: 0,
};

const marketplaceReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case Types.GET_MINING_TOOLS_REQUEST:
      return {
        ...state,
        isGettingMiningTools: true,
        gettingMiningToolsSuccess: false,
        gettingMiningToolsMessage: '',
      };
    case Types.GET_MINING_TOOLS_SUCCESS: {
      const data = get(action, 'payload.records', []);
      const total = get(action, 'payload.total', 0);
      return {
        ...state,
        isGettingMiningTools: false,
        gettingMiningToolsSuccess: true,
        gettingMiningToolsMessage: '',
        listOfMiningTools: data,
        totalOfMiningTools: total,
      };
    }
    case Types.GET_MINING_TOOLS_FAILURE:
      return {
        ...state,
        isGettingMiningTools: false,
        gettingMiningToolsSuccess: false,
        gettingMiningToolsMessage: action.payload.errorMessage,
        listOfMiningTools: [],
        totalOfMiningTools: 0,
      };
    case Types.GET_LANDS_REQUEST:
      return {
        ...state,
        isGettingLands: true,
        gettingLandsSuccess: false,
        gettingLandsMessage: '',
      };
    case Types.GET_LANDS_SUCCESS: {
      const data = get(action, 'payload.records', []);
      const total = get(action, 'payload.total', 0);
      return {
        ...state,
        isGettingLands: false,
        gettingLandsSuccess: true,
        gettingLandsMessage: '',
        listOfLands: data,
        totalOfLands: total,
      };
    }
    case Types.GET_LANDS_FAILURE:
      return {
        ...state,
        isGettingLands: false,
        gettingLandsSuccess: false,
        gettingLandsMessage: action.payload.errorMessage,
        listOfLands: [],
        totalOfLands: 0,
      };
    case Types.GET_GENES_REQUEST:
      return {
        ...state,
        isGettingGenes: true,
        gettingGenesSuccess: false,
        gettingGenesMessage: '',
      };
    case Types.GET_GENES_SUCCESS: {
      const data = get(action, 'payload.records', []);
      const total = get(action, 'payload.total', 0);
      return {
        ...state,
        isGettingGenes: false,
        gettingGenesSuccess: true,
        gettingGenesMessage: '',
        listOfGenes: data,
        totalOfGenes: total,
      };
    }
    case Types.GET_GENES_FAILURE:
      return {
        ...state,
        isGettingGenes: false,
        gettingGenesSuccess: false,
        gettingGenesMessage: action.payload.errorMessage,
        listOfGachaBoxes: [],
        totalOfGachaBoxes: 0,
      };
    case Types.GET_GACHA_BOXES_REQUEST:
      return {
        ...state,
        isGettingGachaBoxes: true,
        gettingGachaBoxesSuccess: false,
        gettingGachaBoxesMessage: '',
      };
    case Types.GET_GACHA_BOXES_SUCCESS: {
      const data = get(action, 'payload.records', []);
      const total = get(action, 'payload.total', 0);
      return {
        ...state,
        isGettingGachaBoxes: false,
        gettingGachaBoxesSuccess: true,
        gettingGachaBoxesMessage: '',
        listOfGachaBoxes: data,
        totalOfGachaBoxes: total,
      };
    }
    case Types.GET_GACHA_BOXES_FAILURE:
      return {
        ...state,
        isGettingGachaBoxes: false,
        gettingGachaBoxesSuccess: false,
        gettingGachaBoxesMessage: action.payload.errorMessage,
        listOfGachaBoxes: [],
        totalOfGachaBoxes: 0,
      };
    case Types.GET_TOOL_MATERIALS_REQUEST:
      return {
        ...state,
        isGettingToolMaterials: true,
        gettingToolMaterialsSuccess: false,
        gettingToolMaterialsMessage: '',
      };
    case Types.GET_TOOL_MATERIALS_SUCCESS: {
      const data = get(action, 'payload.records', []);
      const total = get(action, 'payload.total', 0);
      return {
        ...state,
        isGettingToolMaterials: false,
        gettingToolMaterialsSuccess: true,
        gettingToolMaterialsMessage: '',
        listOfToolMaterials: data,
        totalOfToolMaterials: total,
      };
    }
    case Types.GET_TOOL_MATERIALS_FAILURE:
      return {
        ...state,
        isGettingToolMaterials: false,
        gettingToolMaterialsSuccess: false,
        gettingToolMaterialsMessage: action.payload.errorMessage,
        listOfToolMaterials: [],
        totalOfToolMaterials: 0,
      };
    case Types.GET_CURRENT_NFT_REQUEST:
      return {
        ...state,
        currentNFT: undefined,
        isGettingCurrentNFT: true,
        gettingCurrentNFTSuccess: false,
        gettingCurrentNFTMessage: '',
      };
    case Types.GET_CURRENT_NFT_SUCCESS:
      return {
        ...state,
        currentNFT: action.payload,
        isGettingCurrentNFT: false,
        gettingCurrentNFTSuccess: true,
        gettingCurrentNFTMessage: '',
      };
    case Types.GET_CURRENT_NFT_FAILURE:
      return {
        ...state,
        currentNFT: undefined,
        isGettingCurrentNFT: false,
        gettingCurrentNFTSuccess: false,
        gettingCurrentNFTMessage: action.payload.errorMessage,
      };
    case Types.GET_SALE_HISTORIES_REQUEST: {
      return {
        ...state,
        isGettingSaleHistories: true,
        gettingSaleHistoriesSuccess: false,
        gettingSaleHistoriesMessage: '',
        listOfSaleHistories: [],
        totalSaleHistories: 0,
      };
    }
    case Types.GET_SALE_HISTORIES_SUCCESS: {
      const data = get(action, 'payload.records', []);
      const total = get(action, 'payload.total', 0);
      return {
        ...state,
        isGettingSaleHistories: false,
        gettingSaleHistoriesSuccess: true,
        gettingSaleHistoriesMessage: '',
        listOfSaleHistories: data,
        totalSaleHistories: total,
      };
    }
    case Types.GET_SALE_HISTORIES_FAILURE: {
      return {
        ...state,
        isGettingSaleHistories: false,
        gettingSaleHistoriesSuccess: false,
        gettingSaleHistoriesMessage: action.payload.errorMessage,
        listOfSaleHistories: [],
        totalSaleHistories: 0,
      };
    }
    default:
      return state;
  }
};

export default marketplaceReducer;
