import React from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useColorModeValue,
  ButtonProps,
  Text,
  HStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AppSizes, Colors } from 'src/shared';
import { INFT } from 'src/gql/types';

interface IPurchaseConfirmationModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  isApproved: boolean;
  isApproving: boolean;
  onClose: () => void;
  onBuy: () => void;
  onApprove: () => void;
  currentNFT?: INFT | null;
  defaultPrice?: string;
  defaultAmount?: string;
}

const PurchaseConfirmationModal = ({
  isOpen,
  onClose,
  currentNFT,
  onBuy,
  onApprove,
  isLoading,
  isApproved,
  isApproving,
  defaultPrice = '0',
  defaultAmount = '0',
}: IPurchaseConfirmationModalProps) => {
  const { t } = useTranslation();

  const titleText = t('Component:PurchaseConfirmationModal.Title');
  const descriptionText = t('Component:PurchaseConfirmationModal.Description', {
    token: t('Term:Marketplace.FTCToken'),
  });
  const primaryBg = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const secondaryBg = useColorModeValue(Colors.light.secondary, Colors.dark.secondary);
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const borderColor = useColorModeValue(Colors.light.text, Colors.dark.text);
  const modalBackgroundColor = useColorModeValue(
    Colors.light.modalBackground,
    Colors.dark.modalBackground
  );

  let approveButtonStyle: ButtonProps = {
    ...styles.button,
    isDisabled: isApproved,
  };
  if (!isApproved) {
    approveButtonStyle = { ...approveButtonStyle, bg: primaryBg };
  }

  let buyNowButtonStyle: ButtonProps = {
    ...styles.button,
    isDisabled: !isApproved,
    mr: 3,
  };
  if (isApproved) {
    buyNowButtonStyle = { ...buyNowButtonStyle, bg: secondaryBg };
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor={modalBackgroundColor}>
        <ModalHeader textAlign={'center'}>{titleText}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={VStack} pb={6} spacing={4}>
          {!isApproved && (
            <Text color={descriptionColor} textAlign={'center'}>
              {descriptionText}
            </Text>
          )}
          <FormControl>
            <FormLabel>{t('Component:PlaceOrderModal.Input.Token')}:</FormLabel>
            <Input
              borderColor={borderColor}
              placeholder={t('Component:PlaceOrderModal.Holder.Token')}
              value={currentNFT?.metadata?.name}
              disabled
            />
          </FormControl>
          <HStack spacing={4} alignItems="flex-start">
            <FormControl flex={0.6}>
              <FormLabel htmlFor="priceInput">
                {t('Component:PlaceOrderModal.Input.Price')}:
              </FormLabel>
              <InputGroup>
                <InputLeftAddon children="FTC" borderColor={borderColor} />
                <Input
                  borderColor={borderColor}
                  defaultValue={defaultPrice}
                  disabled
                  placeholder={t('Component:PlaceOrderModal.Holder.Price')}
                  type="number"
                />
              </InputGroup>
            </FormControl>
            <FormControl flex={0.4}>
              <FormLabel htmlFor="amountInput">
                {t('Component:PlaceOrderModal.Input.Amount')}:
              </FormLabel>
              <Input
                borderColor={borderColor}
                defaultValue={defaultAmount}
                disabled
                placeholder={t('Component:PlaceOrderModal.Holder.Amount')}
                type="number"
              />
            </FormControl>
          </HStack>
        </ModalBody>

        <ModalFooter mx={'auto'}>
          <Button {...buyNowButtonStyle} onClick={onBuy} isLoading={isLoading}>
            {t('Common:BuyNow')}
          </Button>
          <Button {...approveButtonStyle} onClick={onApprove} isLoading={isApproving}>
            {t('Common:Approve')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PurchaseConfirmationModal;

const styles = {
  button: {
    borderRadius: 'full' as 'full',
    minWidth: AppSizes.ButtonMinWidth,
  },
};
