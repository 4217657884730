import React, { useCallback, useEffect } from 'react';
import { Text, useDisclosure, usePrevious } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isFunction from 'lodash/isFunction';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'src/components';
import { RootReducerState } from 'src/redux';
import useErrorModal from 'src/features/errorModal/hooks/useErrorModal';
import { appUtils } from 'src/common';
import { INFT, TNFTType } from 'src/gql/types';
import { useGetLoginURIWithRedirect, useIsMounted } from 'src/hooks';
import useApproveFTCTokenToFixedPriceSaleMarket from '../hooks/useApproveFTCTokenToFixedPriceSaleMarket';
import useBuyMiningToolFromFixedPriceSale from '../hooks/useBuyNFTFromFixedPriceSaleMarket';
import PurchaseConfirmationModal from './PurchaseConfirmationModal';

interface IBuyButtonProps {
  currentNFT?: INFT | null;
  saleId?: string;
  currentPrice?: string;
  currentAmount?: string;
  onBuySuccess?: (txid: string) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  nftType: TNFTType;
}

const BuyButton = ({
  saleId,
  onBuySuccess,
  isLoading,
  isDisabled,
  currentPrice,
  currentAmount,
  currentNFT,
  nftType,
}: IBuyButtonProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [openErrorModal] = useErrorModal();
  const {
    isOpen: isOpenPurchaseConfirmationModal,
    onOpen: onOpenPurchaseConfirmationModal,
    onClose: onClosePurchaseConfirmationModal,
  } = useDisclosure();

  const isMounted = useIsMounted();

  const [
    onApprovedFTCTokenToFixedPriceSale,
    isGettingApprovedFTCTokenStatus,
    isApprovingFTCToken,
    isApprovedFTCTokenForSale,
    approveFTCTokenToFixedPriceSaleErrorMessage,
  ] = useApproveFTCTokenToFixedPriceSaleMarket(nftType);
  const [
    isBuyingNFTFromFixedPriceSaleMarket,
    onBuyNFTFromFixedPriceSaleMarket,
    buyNFTFromFixedPriceSaleMarketErrorMessage,
  ] = useBuyMiningToolFromFixedPriceSale(nftType);
  const signedIn = useSelector((state: RootReducerState) => state.auth.signedIn);
  const loginURI = useGetLoginURIWithRedirect();
  const onClickNFTApproving = useCallback(() => {
    if (!signedIn) {
      history.push(loginURI);
      return;
    }
    if (isFunction(onApprovedFTCTokenToFixedPriceSale)) {
      onApprovedFTCTokenToFixedPriceSale();
    }
  }, [onApprovedFTCTokenToFixedPriceSale, history, signedIn, loginURI]);

  const onClickBuyNow = useCallback(() => {
    if (!signedIn) {
      history.push(loginURI);
      return;
    }
    // TODO: Check user FTC balance and reject if not enough
    if (isFunction(onBuyNFTFromFixedPriceSaleMarket) && saleId) {
      onBuyNFTFromFixedPriceSaleMarket(saleId, onBuySuccess);
    }
  }, [onBuySuccess, onBuyNFTFromFixedPriceSaleMarket, saleId, history, signedIn, loginURI]);

  const onBuy = useCallback(() => {
    if (!signedIn) {
      history.push(loginURI);
      return;
    }
    onOpenPurchaseConfirmationModal();
  }, [history, signedIn, onOpenPurchaseConfirmationModal, loginURI]);

  const isButtonLoading =
    !!isGettingApprovedFTCTokenStatus ||
    !!isApprovingFTCToken ||
    !!isBuyingNFTFromFixedPriceSaleMarket ||
    !!isLoading;
  const isButtonDisabled = isButtonLoading || isDisabled;

  let buttonLoadingText;
  if (isApprovingFTCToken) {
    buttonLoadingText = t('Common:Approving');
  }

  const prevApproveFTCTokenToFixedPriceSaleErrorMessage = usePrevious<string | undefined>(
    approveFTCTokenToFixedPriceSaleErrorMessage as string
  );

  useEffect(() => {
    if (!isMounted()) return;
    if (
      prevApproveFTCTokenToFixedPriceSaleErrorMessage !==
        approveFTCTokenToFixedPriceSaleErrorMessage &&
      approveFTCTokenToFixedPriceSaleErrorMessage
    ) {
      if (isFunction(openErrorModal)) openErrorModal(approveFTCTokenToFixedPriceSaleErrorMessage);
    }
  }, [
    isMounted,
    prevApproveFTCTokenToFixedPriceSaleErrorMessage,
    approveFTCTokenToFixedPriceSaleErrorMessage,
    openErrorModal,
  ]);

  const prevBuyMiningToolFromFixedPriceSaleErrorMessage = usePrevious<string | undefined>(
    buyNFTFromFixedPriceSaleMarketErrorMessage as string
  );
  useEffect(() => {
    if (!isMounted()) return;
    if (
      prevBuyMiningToolFromFixedPriceSaleErrorMessage !==
        buyNFTFromFixedPriceSaleMarketErrorMessage &&
      buyNFTFromFixedPriceSaleMarketErrorMessage
    ) {
      if (isFunction(openErrorModal)) openErrorModal(buyNFTFromFixedPriceSaleMarketErrorMessage);
    }
  }, [
    isMounted,
    prevBuyMiningToolFromFixedPriceSaleErrorMessage,
    buyNFTFromFixedPriceSaleMarketErrorMessage,
    openErrorModal,
  ]);

  return (
    <>
      <SecondaryButton
        isLoading={isButtonLoading}
        loadingText={buttonLoadingText}
        borderRadius={'full'}
        onClick={onBuy}
        disabled={isButtonDisabled}
      >
        <Text>{t('Component:BuyButton.BuyNow')}</Text>
      </SecondaryButton>
      {isOpenPurchaseConfirmationModal && (
        <PurchaseConfirmationModal
          currentNFT={currentNFT}
          isLoading={isButtonLoading}
          isOpen={isOpenPurchaseConfirmationModal}
          onClose={onClosePurchaseConfirmationModal}
          onApprove={onClickNFTApproving as () => void}
          onBuy={onClickBuyNow as () => void}
          isApproved={isApprovedFTCTokenForSale as boolean}
          isApproving={isApprovingFTCToken as boolean}
          defaultPrice={appUtils
            .parseDisplayPriceFromNumberWithDecimal(currentPrice || '0')
            .toString()}
          defaultAmount={currentAmount}
        />
      )}
    </>
  );
};

export default BuyButton;
