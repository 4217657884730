export enum OrderByDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type OrderByDirectionType = 'asc' | 'desc';

export enum OrderByKey {
  INDEX = 'index',
  UPDATED_AT = 'updatedAt',
  RARITY = 'rarity',
}

export interface IPaginationInput {
  limit: number;
  skip: number;
}

export interface IOrderByKeyInput {
  [OrderByKey.INDEX]?: OrderByDirectionType;
  [OrderByKey.UPDATED_AT]?: OrderByDirectionType;
  [OrderByKey.RARITY]?: OrderByDirectionType;
}

export interface IMarketPrice {
  origin: string;
  originalCurrency: string;
  usdt: string;
  bnb: string;
  eth: string;
  btc: string;
}

export interface ITokenIdDetail {
  index: number;
  templateId: string;
  contractId: string;
}

export interface IUserMeta {
  address: string;
  country: string;
  ip: string;
}

export interface IMiningToolMetadata {
  templateId: number;
  name: string;
  cat: number;
  rarity: number;
  supply: number;
  weight: number;
  cd: number;
  mp: number;
  luck: number;
}

export interface ILandMetadata {
  templateId: number;
  name: string;
  supply: number;
  weight: number;
  cd: number;
  mp: number;
  luck: number;
}

export interface IGeneMetadata {
  templateId: number;
  name: string;
  supply: number;
  weight: number;
  geneClass: number;
  part: number;
  rarity: number;
}

export interface INFT {
  _id: string;
  tokenId: string;
  tokenIdDetail: ITokenIdDetail;
  owner: string;
  format: string;
  type: string;
  metadata: TNFTMetadata;
  totalAmount: string;
  onSaleAmount: string;
  salePrice: string;
  priceCurrency: string;
}

export interface IMiningTool extends INFT {
  metadata: IMiningToolMetadata;
}

export interface ILand extends INFT {
  metadata: ILandMetadata;
}

export interface IGene extends INFT {
  metadata: IGeneMetadata;
}

export interface IStandardVariable {
  pagination: IPaginationInput;
}

export interface IFilter {
  search?: string;
  cat?: number[];
  rarity?: number[];
  mp?: number[];
  cd?: number[];
  luck?: number[];
  geneClass?: number[];
  part?: number[];
}

export interface IAppVariable extends IStandardVariable {
  type?: TNFTType;
  owner?: string;
  filter?: IFilter;
  orderBy?: IOrderByKeyInput;
}

export interface INFTVariable extends IStandardVariable {
  type?: TNFTType;
  owner?: string;
  filter?: IFilter;
  orderBy?: INFTOrderByType;
}

export interface IFixedPriceSaleNFTVariable extends IStandardVariable {
  type?: TNFTType;
  filter?: INFTFilterByType;
  status?: TFixedPriceSaleStatus;
  orderBy?: INFTOrderByType;
}

export interface IMyNFTDetailVariable {
  tokenId: string;
  owner: string;
}

export type TFixedPriceSaleStatus = 'CANCELED' | 'AVAILABLE' | 'BOUGHT';
export type TNFTType = 'Tool' | 'Land' | 'Gene' | 'Gacha' | 'ToolMaterial';
export type TNFTMetadata = IMiningToolMetadata | ILandMetadata | IGeneMetadata | null;

export interface IFixedPriceSaleNFT {
  id: string;
  tokenId: string;
  status: TFixedPriceSaleStatus;
  seller: IUserMeta;
  sellPrice: IMarketPrice;
  sellTime: number;
  tokenIdDetail: ITokenIdDetail;
  buyer: IUserMeta;
  buyPrice: IMarketPrice;
  buyTime: number;
  item: INFT;
  amount: string;
}

export interface IListFixedPriceSaleNFTResult {
  total: number;
  records: IFixedPriceSaleNFT[];
}

export interface IFixedPriceSaleNFTDetailVariable {
  saleId: string;
  type: TNFTType;
}

export interface INFTsResult {
  total: number;
  records: INFT[];
}

export interface INFTFilterByType {
  land?: IFilter;
  tool?: IFilter;
  gene?: IFilter;
}

export interface INFTOrderByType {
  land?: IOrderByKeyInput;
  tool?: IOrderByKeyInput;
  gene?: IOrderByKeyInput;
  gacha?: IOrderByKeyInput;
  toolMaterial?: IOrderByKeyInput;
}

export interface IGetNFTsVariable extends IStandardVariable {
  filter?: INFTFilterByType;
  type: TNFTType;
  owner?: string;
}

export interface ISummary {
  totalSale: number;
  avgPrice: number;
  avgPriceUSD: number;
  volume: number;
  volumeUSD: number;
  totalTransaction: number;
}

export interface IMarketplaceSummary {
  last24Hours: ISummary;
  last7Days: ISummary;
  last30Days: ISummary;
}

export interface IMarketStatistics {
  totalSale: string;
  totalTransaction: string;
  totalVolume: IMarketPrice;
}

export type TTimeFrame = 'last24h' | 'last7days' | 'last30days' | 'last31days';

export interface ICustomerInformationInput {
  timeZone?: string;
}

export interface IUpdateCustomerInfomationVariable {
  walletAddress: string;
  customerInfo?: ICustomerInformationInput;
}
