import React from 'react';
import { Flex } from '@chakra-ui/react';
import { DashboardLayout } from 'src/layouts';
import { NFTType } from 'src/globals/constants';
import { FixedPriceSaleNFTList } from 'src/features/nftList';
import MarketHeader from '../../components/MarketHeader';

const ToolMaterial = () => {
  return (
    <DashboardLayout>
      <Flex flex={{ base: 1 }} p={{ base: 2, md: 4 }} flexDirection={'column'}>
        <MarketHeader nftType={NFTType.TOOL_MATERIAL} />
        <FixedPriceSaleNFTList nftType={NFTType.TOOL_MATERIAL} />
      </Flex>
    </DashboardLayout>
  );
};

export default ToolMaterial;
