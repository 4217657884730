import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { ISaleInfoFromMetaMask, TokenFormat } from 'src/metamask-provider/type';
import { IFixedPriceSaleNFT, IMarketPrice, INFT } from 'src/gql/types';
import { appUtils } from 'src/common';

export const getCurrentPrice = (
  marketPrice: IMarketPrice,
  salePrice?: string
): { priceCurrency: string | undefined; price: string } => {
  return {
    priceCurrency: marketPrice?.originalCurrency,
    price: salePrice || marketPrice?.origin,
  };
};

export const isAvailableAmountForSale = (
  currentNFT: INFT | null,
  saleInfo?: ISaleInfoFromMetaMask | ISaleInfoFromMetaMask[]
): boolean => {
  // a NFTs want to sale on the marketplace, must be have check available amount for sell
  if (isNil(currentNFT)) return false;
  const totalAmount = appUtils.convertStringToNumber(currentNFT.totalAmount);
  const onSaleAmount = appUtils.convertStringToNumber(currentNFT.onSaleAmount);
  if (isNil(saleInfo)) return totalAmount > onSaleAmount;
  if (Array.isArray(saleInfo)) {
    let currentOnSaleAmount = 0;
    forEach(saleInfo, (i: ISaleInfoFromMetaMask) => {
      currentOnSaleAmount += appUtils.convertStringToNumber(i.amount);
    });
    return totalAmount > currentOnSaleAmount;
  }
  return !saleInfo.isAvailableForSale; // this function used to check the available amount for sell
};

const getOnSaleAmountFromSaleInfo = (
  saleInfo: ISaleInfoFromMetaMask | ISaleInfoFromMetaMask[] | undefined,
  format?: string
): string | undefined => {
  if (saleInfo) {
    if (Array.isArray(saleInfo)) {
      let currentOnSaleAmount = 0;
      forEach(saleInfo, (i: ISaleInfoFromMetaMask) => {
        currentOnSaleAmount += appUtils.convertStringToNumber(i.amount);
      });
      return currentOnSaleAmount.toString();
    } else if (format === TokenFormat.ERC721) {
      return saleInfo.isAvailableForSale ? '1' : '0';
    }
    return saleInfo.amount;
  }
  return '0';
};

export const getAmountInfo = (
  saleInfo: ISaleInfoFromMetaMask | ISaleInfoFromMetaMask[] | undefined,
  currentNFT: INFT | null
): { onSaleAmount: number; forSaleAmount: number; totalAmount: number } => {
  let onSaleAmount, forSaleAmount, totalAmount;

  onSaleAmount = appUtils.convertStringToNumber(
    getOnSaleAmountFromSaleInfo(saleInfo, currentNFT?.format as TokenFormat) ||
      currentNFT?.onSaleAmount
  );
  totalAmount = appUtils.convertStringToNumber(currentNFT?.totalAmount);
  forSaleAmount = totalAmount - onSaleAmount;
  return {
    onSaleAmount,
    forSaleAmount,
    totalAmount,
  };
};

export const getFixedPrice = (price?: string, quantity?: string): number => {
  if (isEmpty(price) || isEmpty(quantity)) return 0;
  return appUtils.parseDisplayPriceFromNumberWithDecimal(price as string) / Number(quantity);
};

export const getListOfOpenSaleOrders = (
  saleInfo: Array<ISaleInfoFromMetaMask> | ISaleInfoFromMetaMask
): Array<ISaleInfoFromMetaMask> => {
  if (isNil(saleInfo)) return [];
  if (Array.isArray(saleInfo)) {
    return orderBy(saleInfo, ['sellTime'], ['desc']);
  }
  if (saleInfo.isAvailableForSale) return [saleInfo]; // ERC-721 only support for isAvailableForSale = true
  return [];
};

export const getCurrentFixedPriceSaleNFT = (
  currentFixedPriceSaleNFTOnState: IFixedPriceSaleNFT | null,
  prevFixedPriceSaleNFT?: IFixedPriceSaleNFT
): IFixedPriceSaleNFT | null => {
  if (isNil(prevFixedPriceSaleNFT)) return currentFixedPriceSaleNFTOnState;
  if (
    isEqual(currentFixedPriceSaleNFTOnState?.id, prevFixedPriceSaleNFT?.id) &&
    isEqual(currentFixedPriceSaleNFTOnState?.item?.type, prevFixedPriceSaleNFT?.item?.type)
  )
    return currentFixedPriceSaleNFTOnState;
  return prevFixedPriceSaleNFT;
};

export const getCurrentNFT = (currentNFTOnState: INFT | null, prevNFT?: INFT): INFT | null => {
  if (isNil(prevNFT)) return currentNFTOnState;
  if (isEqual(currentNFTOnState?.tokenId, prevNFT?.tokenId)) return currentNFTOnState;
  return prevNFT;
};
