import apolloClient from '../apolloClient';
import { IUpdateCustomerInfomationVariable } from '../types';
import { updateCustomerMutation } from './mutation';

export const updateCustomer = async (
  variables: IUpdateCustomerInfomationVariable
): Promise<boolean> => {
  try {
    const response = await apolloClient.query<boolean>({
      query: updateCustomerMutation(),
      variables,
    });
    return Promise.resolve(Boolean(response));
  } catch (error) {
    console.error('updateCustomer, error', error);
    return Promise.reject(error);
  }
};
