import { IFixedPriceSaleNFT } from 'src/gql/types';

export const GET_MINING_TOOLS_REQUEST = 'GET_MINING_TOOLS_REQUEST';
export const GET_MINING_TOOLS_SUCCESS = 'GET_MINING_TOOLS_SUCCESS';
export const GET_MINING_TOOLS_FAILURE = 'GET_MINING_TOOLS_FAILURE';

export const GET_CURRENT_NFT_REQUEST = 'GET_CURRENT_NFT_REQUEST';
export const GET_CURRENT_NFT_SUCCESS = 'GET_CURRENT_NFT_SUCCESS';
export const GET_CURRENT_NFT_FAILURE = 'GET_CURRENT_NFT_FAILURE';

export const GET_LANDS_REQUEST = 'GET_LANDS_REQUEST';
export const GET_LANDS_SUCCESS = 'GET_LANDS_SUCCESS';
export const GET_LANDS_FAILURE = 'GET_LANDS_FAILURE';

export const GET_GENES_REQUEST = 'GET_GENES_REQUEST';
export const GET_GENES_SUCCESS = 'GET_GENES_SUCCESS';
export const GET_GENES_FAILURE = 'GET_GENES_FAILURE';

export const GET_GACHA_BOXES_REQUEST = 'GET_GACHA_BOXES_REQUEST';
export const GET_GACHA_BOXES_SUCCESS = 'GET_GACHA_BOXES_SUCCESS';
export const GET_GACHA_BOXES_FAILURE = 'GET_GACHA_BOXES_FAILURE';

export const GET_TOOL_MATERIALS_REQUEST = 'GET_TOOL_MATERIALS_REQUEST';
export const GET_TOOL_MATERIALS_SUCCESS = 'GET_TOOL_MATERIALS_SUCCESS';
export const GET_TOOL_MATERIALS_FAILURE = 'GET_TOOL_MATERIALS_FAILURE';

export const GET_SALE_HISTORIES_REQUEST = 'GET_SALE_HISTORIES_REQUEST';
export const GET_SALE_HISTORIES_SUCCESS = 'GET_SALE_HISTORIES_SUCCESS';
export const GET_SALE_HISTORIES_FAILURE = 'GET_SALE_HISTORIES_FAILURE';

export interface MarketplaceState {
  isGettingCurrentNFT: boolean;
  gettingCurrentNFTSuccess: boolean;
  gettingCurrentNFTMessage: string;
  currentNFT: IFixedPriceSaleNFT | null;
  isGettingMiningTools: boolean;
  gettingMiningToolsSuccess: boolean;
  gettingMiningToolsMessage: string;
  listOfMiningTools: IFixedPriceSaleNFT[];
  totalOfMiningTools: number;
  isGettingLands: boolean;
  gettingLandsSuccess: boolean;
  gettingLandsMessage: string;
  listOfLands: IFixedPriceSaleNFT[];
  totalOfLands: number;
  isGettingGenes: boolean;
  gettingGenesSuccess: boolean;
  gettingGenesMessage: string;
  listOfGenes: IFixedPriceSaleNFT[];
  totalOfGenes: number;
  isGettingGachaBoxes: boolean;
  gettingGachaBoxesSuccess: boolean;
  gettingGachaBoxesMessage: string;
  listOfGachaBoxes: IFixedPriceSaleNFT[];
  totalOfGachaBoxes: number;
  isGettingToolMaterials: boolean;
  gettingToolMaterialsSuccess: boolean;
  gettingToolMaterialsMessage: string;
  listOfToolMaterials: IFixedPriceSaleNFT[];
  totalOfToolMaterials: number;
  isGettingSaleHistories: boolean;
  gettingSaleHistoriesSuccess: boolean;
  gettingSaleHistoriesMessage: string;
  listOfSaleHistories: IFixedPriceSaleNFT[];
  totalSaleHistories: number;
}
