import React, { useCallback } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppSizes, Colors } from 'src/shared';
import { INFT } from 'src/gql/types';
import { NFTFormat } from 'src/globals/constants';
import { metaMaskCommon } from 'src/metamask-provider';
import * as nftDetailCommon from '../common';

interface ITransferNFTModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: (price: string, amount: string) => void;
  currentNFT: INFT | null;
}

const TransferNFTModal = ({
  isOpen,
  onClose,
  currentNFT,
  onConfirm,
  isLoading,
}: ITransferNFTModalProps) => {
  const { t } = useTranslation();

  const titleText = t('Component:Gift.Title');
  const nftName = currentNFT?.metadata?.name || currentNFT?.tokenId || '';
  const primaryBg = useColorModeValue(Colors.light.primary, Colors.dark.primary);

  const maxAvailableAmountForTransfer = nftDetailCommon.getMaxAvailableAmountForSale(currentNFT);
  const showMaxSelector = maxAvailableAmountForTransfer > 1;

  const onSubmit = useCallback(
    ({ receiverAddress, amount }: { receiverAddress: string; amount: string }) => {
      const amountForTransfer = currentNFT?.format === NFTFormat.ERC721 ? '1' : amount;
      if (receiverAddress && amountForTransfer) {
        onConfirm(receiverAddress, amountForTransfer);
      }
    },
    [onConfirm, currentNFT]
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<{ receiverAddress: string; amount: string }>();

  const setMaxAvailableForTransfer = () => {
    setValue('amount', maxAvailableAmountForTransfer.toString());
  };
  const modalBackgroundColor = useColorModeValue(
    Colors.light.modalBackground,
    Colors.dark.modalBackground
  );
  const borderColor = useColorModeValue(Colors.light.text, Colors.dark.text);
  const amountDisabled = currentNFT?.format === NFTFormat.ERC721 ? true : isLoading;
  const defaultAmount = currentNFT?.format === NFTFormat.ERC721 ? '1' : '';
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ModalContent backgroundColor={modalBackgroundColor}>
          <ModalHeader textAlign={'center'}>{titleText}</ModalHeader>
          <ModalCloseButton />
          <ModalBody as={VStack} pb={6} spacing={4}>
            <FormControl isInvalid={!!errors.receiverAddress}>
              <FormLabel htmlFor="receiverAddress">
                {t('Component:Gift.Input.ReceiveAddress.Label')}:
              </FormLabel>
              <Input
                borderColor={borderColor}
                disabled={isLoading}
                placeholder={t('Component:Gift.Input.ReceiveAddress.Placeholder')}
                type="text"
                {...register('receiverAddress', {
                  required: {
                    value: true,
                    message: t('Component:Gift.Input.ReceiveAddress.Error.Required'),
                  },
                  validate: {
                    // @ts-ignore
                    isValidAddress: (address: string) => {
                      if (metaMaskCommon.isValidAddress(address)) {
                        return true;
                      }
                      return t('Component:Gift.Input.ReceiveAddress.Error.Invalid');
                    },
                  },
                })}
              />
              {errors.receiverAddress && (
                <FormErrorMessage>{errors.receiverAddress?.message}</FormErrorMessage>
              )}
            </FormControl>

            <HStack spacing={4} alignItems="flex-start" width="100%">
              <FormControl flex={0.6}>
                <FormLabel>{t('Component:Gift.Input.Token')}:</FormLabel>
                <InputGroup>
                  <Input disabled borderColor={borderColor} value={nftName} />
                </InputGroup>
              </FormControl>
              <FormControl isInvalid={!!errors.amount} flex={0.4}>
                <FormLabel htmlFor="amount">{t('Component:Gift.Input.Amount.Label')}:</FormLabel>
                <InputGroup size="md">
                  <Input
                    borderColor={borderColor}
                    defaultValue={defaultAmount}
                    disabled={amountDisabled}
                    placeholder={t('Component:Gift.Input.Amount.Placeholder')}
                    type="number"
                    {...register('amount', {
                      disabled: amountDisabled,
                      required: {
                        value: true,
                        message: t('Component:Gift.Input.Amount.Error.Required'),
                      },
                      min: {
                        value: 1,
                        message: t('Component:Gift.Input.Amount.Error.Min', {
                          min: 1,
                        }),
                      },
                      max: {
                        value: maxAvailableAmountForTransfer,
                        message: t('Component:Gift.Input.Amount.Error.Max', {
                          max: maxAvailableAmountForTransfer,
                        }),
                      },
                    })}
                  />
                  {showMaxSelector && (
                    <InputRightElement pr={1}>
                      <Button h="1.75rem" size="xs" onClick={setMaxAvailableForTransfer}>
                        {t('Component:Gift.Button.Max')}
                      </Button>
                    </InputRightElement>
                  )}
                </InputGroup>
                {errors.amount && <FormErrorMessage>{errors.amount.message}</FormErrorMessage>}
              </FormControl>
            </HStack>
            <Text>
              {t('Component:Gift.AvailableForTransfer')}: {maxAvailableAmountForTransfer}
            </Text>
          </ModalBody>

          <ModalFooter mx={'auto'}>
            <Button {...styles.button} type="submit" mr={3} isLoading={isLoading} bg={primaryBg}>
              {t('Component:Gift.Button.GiftNow')}
            </Button>
            <Button {...styles.button} onClick={onClose} disabled={isLoading}>
              {t('Common:Cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default TransferNFTModal;

const styles = {
  button: {
    borderRadius: 'full' as 'full',
    minWidth: AppSizes.ButtonMinWidth,
  },
};
