import React from 'react';
import { Flex, Spinner, useColorModeValue } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { EmptyView } from 'src/components';
import { Colors } from 'src/shared';
import { IFixedPriceSaleNFT, INFT } from 'src/gql/types';
import useIsMaxWidth1024 from '../../../hooks/useIsMaxWidth1024';

interface ListingProps<ItemT> {
  loading: boolean;
  data: ReadonlyArray<ItemT>;
  renderItem: ({ item, index }: { item: ItemT; index: number }) => JSX.Element;
  total?: number;
  emptyDescription?: string;
}

const Listing = (props: ListingProps<INFT | IFixedPriceSaleNFT>) => {
  const { loading, data, renderItem, emptyDescription } = props;
  const primaryColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const emptyColor = useColorModeValue(Colors.light.bg, Colors.dark.bg);
  const isMaxWidth1024 = useIsMaxWidth1024();

  return (
    <Flex flex={{ base: 1 }} flexDirection={'column'} pos={'relative'}>
      {!isEmpty(data) && (
        <Flex
          pt={'4'}
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={isMaxWidth1024 ? 'center' : 'start'}
        >
          {data.map((item: INFT | IFixedPriceSaleNFT, index: number) =>
            renderItem({ item, index })
          )}
        </Flex>
      )}
      {!loading && isEmpty(data) && <EmptyView description={emptyDescription} mt={'4'} />}
      {loading && (
        <Spinner
          className={'spinner'}
          thickness="5px"
          speed="0.65s"
          emptyColor={emptyColor}
          color={primaryColor}
          size="xl"
          pos={'absolute'}
        />
      )}
    </Flex>
  );
};

export default Listing;
