import { DeepPartial } from '@chakra-ui/react';
import { IUserActivity } from 'src/gql/user-activity/types';
import { SaleTransactionEvent } from 'src/globals/constants';

export const getActivityLabel = (params?: DeepPartial<Pick<IUserActivity, 'eventName'>>) => {
  const LABEL = {
    [SaleTransactionEvent.SaleAdded]: 'Component:UserActivity.Label.SaleAdded',
    [SaleTransactionEvent.SalePriceChanged]: 'Component:UserActivity.Label.SalePriceChanged',
    [SaleTransactionEvent.SaleCanceled]: 'Component:UserActivity.Label.SaleCancel',
    [SaleTransactionEvent.SaleDone]: 'Component:UserActivity.Label.SaleDone',
    [SaleTransactionEvent.Approval]: 'Component:UserActivity.Label.Approval',
    [SaleTransactionEvent.TransferSingle]: 'Component:UserActivity.Label.Transfer',
    [SaleTransactionEvent.TransferBatch]: 'Component:UserActivity.Label.Transfer',
  };
  const { eventName } = params || {};
  return eventName ? LABEL[eventName] || eventName : 'Component:UserActivity.Label.Unknown';
};
