import React, { useCallback, useEffect, useState } from 'react';
import {
  Flex,
  Button,
  Image,
  Spinner,
  useColorModeValue,
  Text,
  VStack,
  HStack,
  Box,
  Heading,
  Link,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useHistory, withRouter, RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';
import { ChevronLeftIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isFunction from 'lodash/isFunction';
import { Colors } from 'src/shared';
import { MarketplaceAction, RootReducerState } from 'src/redux';
import BN from 'bn.js';
import { TNFTType, TNFTMetadata, IFixedPriceSaleNFT } from 'src/gql/types';
import { appUtils, coreUtils } from 'src/common';
import { Label } from 'src/components';
import { SaleHistoryList } from 'src/features/saleHistoryList';
import { SEO } from 'src/seo';
import { isShowSaleHistories } from 'src/globals/configs';
import {
  TransactionSubmittedModal,
  BuyButton,
  CancelSellButton,
  UpdateSellPriceButton,
  useGetFixedPriceSaleInfoBySaleId,
} from 'src/features/trading';
import { getCurrentFixedPriceSaleNFT } from '../common';
import * as Utils from '../../../common/coreUtils';
import { NFTType } from '../../../globals/constants';
import { ISaleInfoFromMetaMask } from '../../../metamask-provider/type';
import * as AssetsService from '../../../services/assets';
import NFTAttributes from './NFTAttributes';
import NFTAbouts from './NFTAbouts';
import FixedPriceSaleNFTSaleInfo from './FixedPriceSaleNFTSaleInfo';

interface ParamTypes {
  saleId: string;
}
interface LocationState {
  currentFixedPriceSaleNFT?: IFixedPriceSaleNFT;
}

interface OwnProps extends RouteComponentProps<ParamTypes, {}, LocationState> {
  nftType: TNFTType;
}

interface StateProps {
  currentFixedPriceSaleNFT: IFixedPriceSaleNFT | null;
  isGettingCurrentNFT: boolean;
  metaMaskWalletAddress?: string;
}

interface DispatchProps {
  onGetCurrentNFTBySaleId: (saleId: string) => void;
}

type FixedPriceSaleNFTDetailProps = OwnProps & StateProps & DispatchProps;

const FixedPriceSaleNFTDetail = (props: FixedPriceSaleNFTDetailProps) => {
  const {
    onGetCurrentNFTBySaleId,
    currentFixedPriceSaleNFT,
    isGettingCurrentNFT,
    metaMaskWalletAddress,
    nftType,
    match,
  } = props;

  const primaryColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const emptyColor = useColorModeValue(Colors.light.bg, Colors.dark.bg);
  const borderColor = useColorModeValue(Colors.light.divider, Colors.dark.divider);
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const textColor = useColorModeValue(Colors.light.text, Colors.dark.text);
  const defaultNFTMetadata = coreUtils.getDefaultMetadataByNftType(nftType);

  const { t } = useTranslation();
  const [currentTxId, setCurrentTxId] = useState<string | undefined>();
  const [metadata, setMetadata] = useState<TNFTMetadata>(defaultNFTMetadata);
  const [quantity, setQuantity] = useState<string>('1');

  const {
    isOpen: isOpenTransactionSubmittedModal,
    onOpen: onOpenTransactionSubmittedModal,
    onClose: onCloseTransactionSubmittedModal,
  } = useDisclosure();
  const history = useHistory();

  const saleId = match?.params?.saleId;
  useEffect(() => {
    onGetCurrentNFTBySaleId(saleId);
  }, [onGetCurrentNFTBySaleId, saleId]);

  const [isGettingSaleInfoBySaleId, saleInfo, getFixedPriceSaleInfoBySaleId] =
    useGetFixedPriceSaleInfoBySaleId(nftType) as [
      boolean,
      ISaleInfoFromMetaMask | undefined,
      (saleId?: string) => void
    ];

  const onGetSaleInfo = useCallback(() => {
    if (isFunction(getFixedPriceSaleInfoBySaleId)) {
      getFixedPriceSaleInfoBySaleId(saleId);
    }
  }, [saleId, getFixedPriceSaleInfoBySaleId]);

  useEffect(() => {
    onGetSaleInfo();
  }, [onGetSaleInfo]);

  useEffect(() => {
    if (currentFixedPriceSaleNFT && currentFixedPriceSaleNFT.id === saleId) {
      setQuantity(currentFixedPriceSaleNFT?.amount);
      setMetadata(get(currentFixedPriceSaleNFT, 'item.metadata', defaultNFTMetadata));
    }
  }, [currentFixedPriceSaleNFT, defaultNFTMetadata, saleId, setMetadata, setQuantity]);

  useEffect(() => {
    if (metadata?.templateId !== defaultNFTMetadata?.templateId) {
      return;
    }

    if (saleInfo) {
      const nftTokenId = saleInfo.tokenId!;
      setQuantity(saleInfo.amount || '1');

      if (nftType === NFTType.MINING_TOOL) {
        AssetsService.getToolMetadata(nftTokenId).then((res) => {
          setMetadata(res);
        });
      } else if (nftType === NFTType.GENE) {
        AssetsService.getGeneMetadata(nftTokenId).then((res) => {
          setMetadata(res);
        });
      } else if (nftType === NFTType.LAND) {
        AssetsService.getLandMetadata(nftTokenId).then((res) => {
          setMetadata(res);
        });
      }
    }
  }, [saleInfo, metadata, defaultNFTMetadata?.templateId, nftType, setMetadata, setQuantity]);

  // Get nft item image based on token ID
  const nftItemImage = React.useMemo(() => {
    const nftTokenId = currentFixedPriceSaleNFT
      ? currentFixedPriceSaleNFT.tokenId
      : saleInfo?.tokenId;

    if (nftTokenId) {
      return nftType === NFTType.LAND
        ? coreUtils.getImageByTokenId(new BN(nftTokenId), nftType)
        : coreUtils.getImageByTemplateId(Utils.getTemplateId(new BN(nftTokenId)), nftType);
    } else {
      return '#';
    }
  }, [currentFixedPriceSaleNFT, saleInfo, nftType]);

  const owner = coreUtils.getOwnerInfoByStatus(currentFixedPriceSaleNFT as IFixedPriceSaleNFT);
  const titleSectionStyle = {
    ...styles.titleSection,
    color: descriptionColor,
    borderBottom: `1px solid ${borderColor}`,
  };
  const NFTContractAddress = appUtils.getNFTContractAddress(nftType);
  const NFTContractDisplay = appUtils.shortenHash(NFTContractAddress);
  const NFTContractURL = appUtils.getAddressExplorerLink(NFTContractAddress);
  const name = get(metadata, 'name') || '';
  const index = get(currentFixedPriceSaleNFT, 'tokenIdDetail.index', '');
  const ownerDisplay = appUtils.shortenHash((owner?.address as string) || '');
  const ownerAddressURL = appUtils.getAddressExplorerLink((owner?.address as string) || '');
  const tokenIndex = `#${index}`;
  const isOwner = appUtils.isAddressEqual(metaMaskWalletAddress, owner?.address);
  const isLoadSuccess = !isGettingCurrentNFT && currentFixedPriceSaleNFT;
  const priceInfo = coreUtils.getPriceInfoByStatus(currentFixedPriceSaleNFT as IFixedPriceSaleNFT);
  const salePrice = get(saleInfo, 'price');
  const isAvailableForSale = get(saleInfo, 'isAvailableForSale', false);
  const isBuyShowed = !isOwner && isAvailableForSale;
  const isActionShowed = isOwner && isAvailableForSale;

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onTransactionSubmitted = useCallback(
    (txid) => {
      onOpenTransactionSubmittedModal();
      setCurrentTxId(txid);
    },
    [onOpenTransactionSubmittedModal, setCurrentTxId]
  );

  const onBuySuccess = useCallback(
    (txid: string) => {
      onTransactionSubmitted(txid);
      onGetSaleInfo();
    },
    [onTransactionSubmitted, onGetSaleInfo]
  );

  const onUpdatePriceSuccess = onBuySuccess;
  const onCancelSellDone = onBuySuccess;

  return (
    <>
      <SEO title={`${name} (${tokenIndex})`} image={nftItemImage} />
      <Flex {...styles.container}>
        <VStack {...styles.leftContainer}>
          <Button {...styles.backButton} onClick={onBack}>
            <ChevronLeftIcon h={6} w={6} />
            <Text>{t('Back')}</Text>
          </Button>
          {nftType === NFTType.LAND ? (
            <Image {...styles.landImage} src={nftItemImage} />
          ) : (
            <Image {...styles.image} src={nftItemImage} />
          )}
        </VStack>
        <VStack {...styles.rightContainer}>
          <Label
            label={tokenIndex}
            bg={borderColor}
            alignSelf={'flex-start'}
            px={4}
            minWidth={'50px'}
          />
          <Flex {...styles.gridDetail}>
            <Heading as="h2" size="lg" color="heading">
              {name}
            </Heading>
            <Box>
              {!!NFTContractAddress && (
                <Tooltip label={NFTContractAddress}>
                  <Link href={NFTContractURL} fontWeight={'bold'} color={textColor} isExternal>
                    {NFTContractDisplay} <ExternalLinkIcon mx="2px" mb="4px" />
                  </Link>
                </Tooltip>
              )}
            </Box>
          </Flex>
          {isLoadSuccess && (
            <HStack>
              {(isBuyShowed || (isGettingSaleInfoBySaleId as boolean)) && (
                <BuyButton
                  currentNFT={currentFixedPriceSaleNFT?.item}
                  saleId={saleId}
                  currentPrice={salePrice}
                  onBuySuccess={onBuySuccess}
                  isLoading={isGettingSaleInfoBySaleId as boolean}
                  nftType={nftType}
                  currentAmount={currentFixedPriceSaleNFT?.amount}
                  isDisabled={isOwner || !isAvailableForSale}
                />
              )}
              {isActionShowed && (
                <UpdateSellPriceButton
                  currentNFT={currentFixedPriceSaleNFT?.item}
                  saleId={saleId}
                  currentPrice={salePrice}
                  isLoading={isGettingSaleInfoBySaleId as boolean}
                  onUpdateSellPriceDone={onUpdatePriceSuccess}
                  nftType={nftType}
                  currentAmount={currentFixedPriceSaleNFT?.amount}
                  isDisabled={!isOwner || !isAvailableForSale}
                />
              )}
              {isActionShowed && (
                <CancelSellButton
                  saleId={saleId}
                  isLoading={isGettingSaleInfoBySaleId as boolean}
                  onCancelSellDone={onCancelSellDone}
                  nftType={nftType}
                  isDisabled={!isOwner || !isAvailableForSale}
                />
              )}
            </HStack>
          )}
          <FixedPriceSaleNFTSaleInfo
            priceInfo={priceInfo}
            salePrice={salePrice}
            quantity={quantity}
          />
          <NFTAbouts metadata={metadata} nftType={nftType} titleStyle={titleSectionStyle} />
          <Box>
            {owner?.address && (
              <Text color={descriptionColor}>
                {t('Component:NFTDetail.OwnedBy')}{' '}
                <Tooltip label={owner?.address}>
                  <Link href={ownerAddressURL} fontWeight={'bold'} color={textColor} isExternal>
                    {ownerDisplay} <ExternalLinkIcon mx="2px" mb="4px" />
                  </Link>
                </Tooltip>
              </Text>
            )}
          </Box>
          <NFTAttributes metadata={metadata} nftType={nftType} titleStyle={titleSectionStyle} />
          {isShowSaleHistories && !!currentFixedPriceSaleNFT?.tokenId && (
            <SaleHistoryList
              nftType={nftType}
              tokenId={currentFixedPriceSaleNFT?.tokenId}
              titleStyle={titleSectionStyle}
            />
          )}
        </VStack>
      </Flex>
      {isGettingCurrentNFT && (
        <Spinner
          {...styles.loading}
          className={'spinner'}
          emptyColor={emptyColor}
          color={primaryColor}
        />
      )}
      <TransactionSubmittedModal
        isOpen={isOpenTransactionSubmittedModal}
        onClose={onCloseTransactionSubmittedModal}
        txid={currentTxId}
      />
    </>
  );
};

const mapStateToProps = (state: RootReducerState, ownProps: OwnProps) => {
  const { marketplace, wallet } = state;
  const { currentNFT, isGettingCurrentNFT } = marketplace;
  const { metaMaskWalletAddress } = wallet;

  return {
    currentFixedPriceSaleNFT: getCurrentFixedPriceSaleNFT(
      currentNFT,
      ownProps.location?.state?.currentFixedPriceSaleNFT
    ),
    isGettingCurrentNFT,
    metaMaskWalletAddress,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    onGetCurrentNFTBySaleId: (saleId: string) =>
      dispatch<any>(MarketplaceAction.getCurrentNFT(ownProps.nftType, saleId)),
  };
};

export default withRouter(
  connect<StateProps, DispatchProps, OwnProps, RootReducerState>(
    mapStateToProps,
    mapDispatchToProps
  )(FixedPriceSaleNFTDetail)
);

const styles = {
  container: {
    flex: { base: 1 },
    p: { base: 2, md: 4 },
    width: '100%',
    flexDirection: { base: 'column' as 'column', md: 'row' as 'row' },
    maxWidth: { base: 'none' as 'none', lg: '1024px', xl: '1200px' },
    alignSelf: 'center',
  },
  leftContainer: {
    flex: { base: 'initial' as 'initial', md: 1 },
    p: { base: 0, md: 4 },
    py: { base: 4 },
    alignItems: 'center',
  },
  rightContainer: {
    flex: { base: 1 },
    pt: { base: 2, md: 4 },
    alignItems: 'inherit' as 'inherit',
    pos: 'relative' as 'inherit',
    spacing: 4,
  },
  image: {
    mt: 0,
    px: 4,
    width: '100%',
    maxWidth: '400px',
    objectFit: 'contain' as 'contain',
  },
  landImage: {
    mt: 0,
    width: '100%',
    maxWidth: '450px',
    objectFit: 'contain' as 'contain',
    borderRadius: '4px',
    border: '1px solid white',
  },
  backButton: { borderRadius: 'full', alignSelf: 'flex-start' },
  loading: {
    speed: '0.65s',
    size: 'xl',
    pos: 'absolute' as 'absolute',
    thickness: '5px',
  },
  gridDetail: {
    flexDirection: 'column' as 'column',
  },
  leftGridDetail: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '100%',
    overflow: 'hidden' as 'hidden',
  },
  titleSection: {
    fontSize: 'lg',
    pt: 4,
    pb: 2,
    fontWeight: 'bold' as 'bold',
  },
};
