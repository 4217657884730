import { gql } from '@apollo/client';

export const GetUserActivitiesQuery = gql`
  query GetUserActivities($pagination: PaginationInput!, $user: String!) {
    getUserActivities(pagination: $pagination, user: $user) {
      records {
        eventName
        transaction {
          transactionHash
        }
        time
        item {
          ... on MiningTool {
            tokenId
            type
            metadata {
              name
              templateId
            }
          }
          ... on Land {
            tokenId
            type
            metadata {
              name
              templateId
            }
          }
          ... on Gene {
            tokenId
            type
            metadata {
              name
              templateId
            }
          }
          ... on GachaBox {
            tokenId
            type
          }
          ... on ToolMaterial {
            tokenId
            type
          }
        }
      }
      total
    }
  }
`;
